import { useState, useEffect } from 'react'
import breeds from '../data/breeds'

import { AutocompleteDropdown } from 'react-native-autocomplete-dropdown'

export default function AutoComplete(props) {
    const [selectedItem, setSelectedItem] = useState(null)

    useEffect(
        () => {
            selectedItem && props.handleSelect(selectedItem.title)
        },
        [selectedItem, props.handleSelect],
    )

    return (
        <>
            {
                props.guessedCorrectly === null
                    ?
                    <AutocompleteDropdown
                        // newest edits
                        showClear={false}
                        suggestionsListMaxHeight={160}
                        suggestionsListContainerStyle={{ borderRadius: 5, backgroundColor: '#D9ECFC' }}
                        // *****
                        onFocus={() => { props.handleSelect(null) }}
                        inputHeight={40}
                        clearOnFocus={true}
                        onSelectItem={setSelectedItem}
                        dataSet={breeds}
                        direction={'up'}
                        textInputProps={{
                            placeholder: 'Type your guess...',
                            style: {
                                textAlign: props.singlebreed ? 'center' : 'left',
                                borderColor: 'gray',
                                borderWidth: 1
                            }
                        }}
                    />
                    :
                    props.guessedCorrectly === true
                        ?
                        <AutocompleteDropdown
                            showClear={false}
                            showChevron={false}
                            //*****
                            inputHeight={40}
                            clearOnFocus={false}
                            textInputProps={{
                                editable: false,
                                // selectTextOnFocus: false,
                                placeholder: props.breed,
                                placeholderTextColor: 'white',
                                style: {
                                    textAlign: 'center',
                                    backgroundColor: '#00CC00',
                                    color: 'white',
                                    fontWeight: 'bold',
                                    outline: 'none'
                                }
                            }}
                        />
                        :
                        <AutocompleteDropdown
                            showClear={false}
                            showChevron={false}
                            //*****
                            inputHeight={20}
                            clearOnFocus={false}
                            textInputProps={{
                                editable: false,
                                // selectTextOnFocus: false,
                                placeholder: props.breed,
                                placeholderTextColor: 'white',
                                style: {
                                    textAlign: 'center',
                                    backgroundColor: props.gameEnded !== null ? '#E7E7E7' : '#DD0000',
                                    color: 'white',
                                    fontWeight: 'bold',
                                    outline: 'none'
                                }
                            }}
                        />

            }

        </>

    )
}