import { useState } from "react"
import CountdownTimer from "./CountdownTimer"

const CountdownTimerDisplay = () => {
  const targetDateWIP = new Date()
  targetDateWIP.setDate(targetDateWIP.getDate() + 1)
  targetDateWIP.setHours(0, 0, 0)
  targetDateWIP.setMilliseconds(0)
  const [targetDate, setTargetDate] = useState(new Date(targetDateWIP))

  return (
    <CountdownTimer targetDate={targetDate} />
  )
}

export default CountdownTimerDisplay
