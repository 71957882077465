import { Image, Pressable, Share, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import CountdownTimerDisplay from '../countdown/CountdownTimerDisplay'
import * as WebBrowser from 'expo-web-browser'

import app from '../firebaseConfig'
import { getAnalytics, logEvent } from 'firebase/analytics'
const analytics = getAnalytics(app)

export default function PostGameMessage(props) {
    const shareStats_SB = async () => {
        try {
            const dogNumberLS = JSON.parse(await AsyncStorage.getItem('dogNumber'))
            const breedsCorrectObjectLS = JSON.parse(await AsyncStorage.getItem('sbBreedsCorrect'))
            const row1 = `${breedsCorrectObjectLS.sbBreed1Correct === true ? '🐶' : breedsCorrectObjectLS.sbBreed1Correct === false ? '⬜' : '▫️'}`
            const row2 = `${breedsCorrectObjectLS.sbBreed2Correct === true ? '🐶' : breedsCorrectObjectLS.sbBreed2Correct === false ? '⬜️' : '▫️'}`
            const row3 = `${breedsCorrectObjectLS.sbBreed3Correct === true ? '🐶' : breedsCorrectObjectLS.sbBreed3Correct === false ? '⬜️' : '▫️'}`
            const row4 = `${breedsCorrectObjectLS.sbBreed4Correct === true ? '🐶' : breedsCorrectObjectLS.sbBreed4Correct === false ? '⬜️' : '▫️'}`
            const row5 = `${breedsCorrectObjectLS.sbBreed5Correct === true ? '🐶' : breedsCorrectObjectLS.sbBreed5Correct === false ? '⬜️' : '▫️'}`

            const numOfTrues = Object.values(breedsCorrectObjectLS).reduce((a, item) => a + item, 0)
            await Share.share({
                message: `Do you know the breed of this dog?\n\nPlay the dog guessing game at: https://doggle.app \n\nDOGGLE #${dogNumberLS}\n${row1}${row2}${row3}${row4}${row5}`,
            })
            // if (result.action === Share.sharedAction) {
            //     if (result.activityType) {
            //         // shared with activity type of result.activityType
            //     } else {
            //         // shared
            //     }
            if (result.action === Share.dismissedAction) {
                return
            }
            logEvent(analytics, 'share_single_breed_(game_screen)')
        } catch (error) {
            console.log(error)
            logEvent(analytics, 'ERROR_share_single_breed_(game_screen)')
            if (error.message == 'Share is not supported in this browser') {
                alert('Sorry, sharing not supported on this browser/device')
            }
        }
    }

    const shareStats_MB = async () => {
        try {
            const dogNumberLS = JSON.parse(await AsyncStorage.getItem('dogNumber'))
            const breedsCorrectObjectLS = JSON.parse(await AsyncStorage.getItem('mbBreedsCorrect'))
            const row1 = `${(breedsCorrectObjectLS.mbBreedA1Correct || breedsCorrectObjectLS.mbBreedB1Correct) === true ? '🐶' : (breedsCorrectObjectLS.mbBreedA1Correct === false || breedsCorrectObjectLS.mbBreedB1Correct === false) ? '⬜' : '▫️'}`
            const row2 = `${(breedsCorrectObjectLS.mbBreedA2Correct || breedsCorrectObjectLS.mbBreedB2Correct) === true ? '🐶' : (breedsCorrectObjectLS.mbBreedA2Correct === false || breedsCorrectObjectLS.mbBreedB2Correct === false) ? '⬜' : '▫️'}`
            const row3 = `${(breedsCorrectObjectLS.mbBreedA3Correct || breedsCorrectObjectLS.mbBreedB3Correct) === true ? '🐶' : (breedsCorrectObjectLS.mbBreedA3Correct === false || breedsCorrectObjectLS.mbBreedB3Correct === false) ? '⬜' : '▫️'}`
            const row4 = `${(breedsCorrectObjectLS.mbBreedA4Correct || breedsCorrectObjectLS.mbBreedB4Correct) === true ? '🐶' : (breedsCorrectObjectLS.mbBreedA4Correct === false || breedsCorrectObjectLS.mbBreedB4Correct === false) ? '⬜' : '▫️'}`
            const row5 = `${(breedsCorrectObjectLS.mbBreedA5Correct || breedsCorrectObjectLS.mbBreedB5Correct) === true ? '🐶' : (breedsCorrectObjectLS.mbBreedA5Correct === false || breedsCorrectObjectLS.mbBreedB5Correct === false) ? '⬜' : '▫️'}`            

            const numOfTrues = Object.values(breedsCorrectObjectLS).reduce((a, item) => a + item, 0)
            await Share.share({
                message: `Do you know the breed of this dog?\n\nPlay the dog guessing game at: https://doggle.app \n\nDOGGLE #${dogNumberLS}\n${numOfTrues}/2\n${row1}${row2}${row3}${row4}${row5}`,
            })
            // if (result.action === Share.sharedAction) {
            //     if (result.activityType) {
            //         // shared with activity type of result.activityType
            //     } else {
            //         // shared
            //     }
            if (result.action === Share.dismissedAction) {
                return
            }
            logEvent(analytics, 'share_mixed_breed_(game_screen)')
        } catch (error) {
            console.log(error)
            logEvent(analytics, 'ERROR_share_mixed_breed_(game_screen)')
            if (error.message == 'Share is not supported in this browser') {
                alert('Sorry, sharing not supported on this browser/device')
            }
        }
    }

    const openDogInsta = async () => {
        logEvent(analytics, 'open_dog_insta')
        await WebBrowser.openBrowserAsync(`https://www.instagram.com/${props.owner[3].substring(1)}`)
    }

    const openDogTikTok = async () => {
        logEvent(analytics, 'open_dog_tiktok')
        await WebBrowser.openBrowserAsync(`https://www.tiktok.com/${props.owner[3]}`)
    }

    const openModal = () => {
        props.setModalVisible(true)
        logEvent(analytics, 'open_modal')
    }

    const vowelsArr = ['A', 'a', 'E', 'e', 'I', 'i', 'O', 'o', 'U', 'u']

    return (
        <View style={styles.container}>

            <View style={{ marginVertical: 15 }}>
                {
                    props.owner.length === 1 ?
                        <Pressable style={{ textDecoration: 'underline' }} onPress={() => props.setModalVisible(true)}>
                            {props.singleBreed
                                ?
                                <Text style={{ textAlign: 'center', fontSize: 14, fontWeight: 600 }}>{props.breed === 'German Shepherd (Alsatian)' ? 'German Shepherd' : props.breed}<Image style={{ marginLeft: 3, height: 10, width: 10 }} source={require("../assets/open.png")} /></Text>
                                :
                                <Text style={{ textAlign: 'center', fontSize: 14, fontWeight: 600 }}>{props.breeds[0] === 'German Shepherd (Alsatian)' ? 'German Shepherd' : props.breeds[0]} & {props.breeds[1] === 'German Shepherd (Alsatian)' ? 'German Shepherd' : props.breeds[1]}<Image style={{ marginLeft: 3, height: 10, width: 10 }} source={require("../assets/open.png")} /></Text>
                            }
                        </Pressable>
                        :
                        <>
                            {
                                props.socialMedia === undefined || props.socialMedia === null
                                    ?
                                    <Pressable style={{ textDecoration: 'underline' }} onPress={openModal}>
                                        <Text style={{ textAlign: 'center', fontSize: 14, fontWeight: 600 }}>This is {props.owner[1]}, {vowelsArr.includes(props.owner[2][0]) ? 'an' : 'a'} {props.owner[2]}<Image style={{ marginLeft: 3, height: 10, width: 10 }} source={require("../assets/open.png")} /></Text>
                                    </Pressable>
                                    :
                                    <>
                                        <Pressable style={{ textDecoration: 'underline' }} onPress={openModal}>
                                            <Text style={{ textAlign: 'center', fontSize: 14, fontWeight: 600 }}>This is {props.owner[1]}, {vowelsArr.includes(props.owner[2][0]) ? 'an' : 'a'} {props.owner[2]}<Image style={{ marginLeft: 3, height: 10, width: 10 }} source={require("../assets/open.png")} /></Text>
                                        </Pressable>
                                        <View style={{ flexDirection: "row", flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                                            <Text>(check them out on Instagram - </Text>
                                            <TouchableOpacity onPress={openDogInsta}>
                                                <Text style={{ textDecorationLine: "underline" }}>{props.socialMedia}</Text>
                                            </TouchableOpacity>
                                            <Text>)</Text>
                                        </View>
                                    </>

                            }
                        </>
                }
            </View>

            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <View style={{ width: 120 }}>
                    <View style={{ alignItems: 'flex-end' }}>
                        <Pressable onPress={props.singleBreed ? shareStats_SB : shareStats_MB} style={{ ...styles.button, flexDirection: 'row' }}>
                            <Image source={require('../assets/share-white.png')} resizeMode="contain" style={{ width: 20, height: 20, marginRight: 10 }} />
                            <Text style={{ color: 'white', fontWeight: 600, fontSize: 16 }}>Share</Text>
                        </Pressable>
                    </View>
                </View>

                <View style={{ width: 1, height: 35, backgroundColor: 'grey', marginHorizontal: 20 }} />

                <View style={{ width: 120 }}>
                    <View style={{ alignItems: 'flex-start' }}>
                        <View style={{ alignItems: 'center' }}>
                            <Text style={{ textAlign: 'center', fontSize: 14 }}>Next DOGGLE in</Text>
                            <CountdownTimerDisplay />
                        </View>
                    </View>
                </View>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingHorizontal: 15
    },
    button: {
        alignSelf: 'center',
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#2C41FF",
        borderRadius: 20,
        borderColor: "gray",
        height: 35,
        padding: 20
    },
    buttonShare: {
        flexDirection: 'row',
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#2C41FF",
        borderColor: "gray",
        marginTop: 5,
        borderRadius: 30,
        paddingVertical: 10,
        paddingHorizontal: 15,
    },
})