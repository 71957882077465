import { useEffect } from 'react'
import { Image, Pressable, StyleSheet, Text, View } from 'react-native'
import * as WebBrowser from 'expo-web-browser'

import app from '../firebaseConfig'
import { getAnalytics, logEvent } from "firebase/analytics"
const analytics = getAnalytics(app)

export default function InfoScreen(props) {
    useEffect(() => {
        logEvent(analytics, 'SCREEN_VIEW_info')
    }, [])

    // const openSubmitDogForm = async () => {
    //     logEvent(analytics, 'open_submit_dog_form')
    //     await WebBrowser.openBrowserAsync('https://tally.so/r/waQJ9E')
    // }

    const handlePressPlay = () => {
        logEvent(analytics, 'press_play')
        props.closeModal()
    }

    return (
        <View style={styles.container}>
            <View style={{ marginHorizontal: 15 }}>
                <Text style={{ marginBottom: 15, fontWeight: 'bold', fontSize: 24, alignSelf: 'center' }}>How to play</Text>

                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <View style={{ width: 70, alignItems: 'center', justifyContent: 'center' }}>
                        <Image
                            style={{ height: 50, width: 50, margin: 15 }}
                            source={require('../assets/Step1_dark2.png')}
                            resizeMode={'contain'}
                        />
                    </View>
                    <Text style={{ marginLeft: 15, fontSize: 16 }}>Guess the breed of the dog. You have 5 attempts.</Text>
                </View>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <View style={{ width: 70, alignItems: 'center', justifyContent: 'center' }}>
                        <Image
                            style={{ height: 60, width: 60, margin: 15 }}
                            source={require('../assets/Step2_dark2.png')}
                            resizeMode={'contain'}
                        />
                    </View>
                    <Text style={{ marginLeft: 15, fontSize: 16 }}>Try both single breed & mixed breed mode.</Text>
                </View>

                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <View style={{ width: 70, alignItems: 'center', justifyContent: 'center' }}>
                        <Image
                            style={{ height: 40, width: 40, margin: 15 }}
                            source={require('../assets/Step3_dark.png')}
                            resizeMode={'contain'}
                        />
                    </View>
                    <Text style={{ marginLeft: 15, fontSize: 16 }}>Share the dog & your score!</Text>
                </View>
            </View>

            <Pressable onPress={handlePressPlay} style={styles.button}>
                <Text style={{ fontWeight: 'bold', color: "white", fontSize: 24 }}>Play</Text>
            </Pressable>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        width: '95%',
        marginBottom: 15,
        alignItems: 'center',
    },
    button: {
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#2C41FF",
        borderRadius: 30,
        paddingVertical: 10,
        paddingHorizontal: 30,
        marginVertical: 15,
    },
})