const singleBreedFacts = {
  Affenpinscher:
    "The Affenpinscher was originally bred to catch rats and mice in homes and stables.",
  African:
    "Also known as the Africanis, this breed is indigenous to Africa and has been present on the continent for thousands of years.",
  Airedale:
    "The Airedale is often referred to as 'King of Terriers' due to their size and versatility.",
  Akita:
    "Akitas are a breed of large, powerful dogs that were originally bred for hunting and guarding in Japan.",
  Appenzeller:
    "Appenzellers were originally bred as herding and guard dogs for Swiss farmers.",
  "Australian Shepherd":
    "Despite their name, Australian Shepherds were actually developed in the United States.",
  Basenji:
    "Basenjis are one of the oldest dog breeds, with evidence of their existence dating back to ancient Egypt.",
  Beagle:
    "Beagles have a keen sense of smell and are often used for scent detection in fields such as law enforcement and agriculture.",
  Bluetick:
    "Blueticks are a breed of coonhound that were originally developed in Louisiana.",
  Borzoi:
    "Borzois are sometimes referred to as the 'Russian wolfhound' due to their history of being used for wolf hunting.",
  Bouvier:
    "Bouviers are a breed of large, powerful dogs that were originally developed in Belgium.",
  Boxer:
    "Boxers are often used as service dogs and are trained for tasks such as guiding the blind, providing emotional support",
  Brabancon:
    "Brabancons were once known as the 'Petit Brabancon' and were often kept as lapdogs by Belgian royalty.",
  Briard:
    "Briards were used by French shepherds to guard their flocks and were also used as messenger dogs during World War I.",
  "Norwegian Buhund":
    "The Norwegian Buhund is a medium-sized spitz breed that originated in Norway and was used as a herding dog.",
  Bulldog:
    "Bulldogs were originally bred for bull-baiting, but the practice was outlawed in the 19th century, and the breed was then developed as a companion dog.",
  "French Bulldog":
    "French Bulldogs are also called 'Frenchies' and were originally bred as companion dogs for wealthy French families.",
  "Staffordshire Bull Terrier":
    "Staffordshire Bull Terriers were originally bred for bull-baiting and were later used in dogfighting, but the breed has since been developed as a family pet.",
  "Australian Cattledog":
    "The Australian Cattle Dog, also known as the Blue Heeler, is a medium-sized breed that was developed in Australia for herding cattle.",
  Chihuahua:
    "Chihuahuas were once believed to have spiritual powers by ancient Mexican civilizations and were often used in religious ceremonies.",
  "Chow Chow":
    "Chow Chows have a thick, fluffy coat that comes in a variety of colors, including red, black, and blue.",
  Clumber:
    "Clumbers were originally used as hunting dogs and were named after Clumber Park in Nottinghamshire,",
  "Border Collie":
    "Border Collies were originally bred for herding sheep in the Scottish-English border region.",
  "Rough Collie":
    "The Rough Collie is a breed that became well known for being featured in the TV show 'Lassie'.",
  Coonhound:
    "Coonhounds are a group of scent hounds that were originally bred for hunting raccoons.",
  Corgi: "The Corgi was originally bred for herding cattle and sheep in Wales.",
  Cotondetulear:
    "The Cotondetulear is a small, fluffy dog breed that originated in Madagascar.",
  Dachshund:
    "Dachshunds were originally bred in Germany to hunt badgers and other burrowing animals.",
  Dalmatian:
    "Dalmatians were originally bred to run alongside horse-drawn carriages and protect the horses from danger.",
  "Great Dane":
    "Great Danes are one of the tallest dog breeds, with some individuals standing over 3 feet tall at the shoulder.",
  "Scottish Deerhound":
    "The Scottish Deerhound is a large dog breed that was originally bred to hunt deer in Scotland.",
  Dhole:
    "Dholes are known for their strong social bonds and their ability to communicate with each other through a variety of vocalizations.",
  Dingo: "The Dingo is a wild dog that is native to Australia.",
  Doberman: "Dobermans were originally bred in Germany as guard dogs.",
  "Norwegian Elkhound":
    "The Norwegian Elkhound is a dog breed that was originally used for hunting moose and other large game in Norway.",
  Entlebucher:
    "The Entlebucher Mountain Dog is a breed that originated in Switzerland.",
  Eskimo:
    "The Eskimo Dog is a breed that is native to the Arctic regions of North America.",
  "Bichon Frise":
    "The Bichon Frise is a small dog breed that is known for its curly, fluffy coat.",
  "German Shepherd (Alsatian)":
    "German Shepherds were originally bred in Germany for herding and guarding sheep.",
  Greyhound:
    "Greyhounds are the fastest dog breed and can reach speeds of up to 45 mph.",
  "Italian Greyhound":
    "Italian Greyhounds are often called 'Iggies' for short and are known for their affectionate and playful personality.",
  Groenendael:
    "The Groenendael is a type of Belgian Shepherd dog that was originally bred for herding sheep.",
  Havanese:
    "Havanese dogs are known for their lively and affectionate personality, making them popular pets.",
  "Afghan Hound":
    "Afghan Hounds have a regal and elegant appearance and are known for their silky, flowing coats.",
  "Barak Hound":
    "The Barak Hound is a rare breed that is primarily used for hunting in India and Pakistan.",
  "Basset Hound":
    "Basset Hounds were originally bred for hunting small game and have an excellent sense of smell.",
  "Blood Hound":
    "Blood Hounds are known for their excellent sense of smell, which makes them great for tracking scents over long distances.",
  "English Hound":
    "English Hounds were originally bred for hunting and are known for their endurance and ability to follow scents over long distances.",
  "Ibizan Hound":
    "The Ibizan Hound is a unique breed that was originally used for hunting rabbits in Spain.",
  "Plott Hound":
    "The Plott Hound is a breed of dog that was originally used for hunting wild boar in North Carolina.",
  "Walker Hound":
    "The Walker Hound is a type of American Foxhound that is known for its excellent sense of smell and endurance.",
  Huntaway:
    "The Huntaway is a breed of dog that is primarily used for herding sheep in New Zealand.",
  Husky:
    "Huskies are a breed of dog that were originally bred for sled pulling in cold climates.",
  Keeshond:
    "The Keeshond is a breed of dog that was originally bred for life on Dutch barges.",
  Kelpie:
    "The Kelpie is a breed of dog that is used for herding sheep in Australia.",
  Komondor:
    "The Komondor's distinctive dreadlock-like coat serves as both insulation and protection from predators.",
  Kuvasz:
    "The Kuvasz is one of the oldest dog breeds, and is believed to have originated in Hungary.",
  Labrador:
    "Labradors were originally bred to retrieve game for hunters, and their excellent sense of smell and love of water make them great hunting companions.",
  "Finnish Lapphund":
    "The Finnish Lapphund is a hardy and versatile dog breed that was originally used for herding reindeer.",
  Leonberg:
    "Leonbergs were created to resemble the lion on the Leonberg coat of arms, and are known for their gentle and affectionate nature.",
  Lhasa: "The Lhasa Apso is an ancient dog breed that originated in Tibet.",
  Malamute:
    "The Alaskan Malamute is a powerful and hardworking dog breed that was originally bred for sled pulling and hauling heavy loads.",
  Malinois:
    "The Malinois is often used as police and military dog, and excel in obedience and agility competitions.",
  Maltese:
    "The Maltese is a small dog breed that was originally bred for companionship.",
  Bullmastiff:
    "The Bullmastiff is a large dog breed that was originally bred in England as a guard dog.",
  "English Mastiff":
    "English Mastiffs were originally bred as war dogs and were later used for guarding estates and castles.",
  "Tibetan Mastiff":
    "The Tibetan Mastiff is a large dog breed that was originally bred to guard flocks, monasteries, and villages in Tibet.",
  "Hairless Mexican":
    "The Hairless Mexican, also known as the Xoloitzcuintli, is an ancient dog breed that was once considered sacred by the Aztecs.",
  "Bernese Mountain":
    "Bernese Mountain dogs were originally used for herding and pulling carts, and are known for their gentle and friendly nature.",
  "Swiss Mountain":
    "Swiss Mountain dogs are known for their impressive strength and were originally bred to be draft animals, pulling carts or carrying loads through mountainous terrain.",
  "Large Münsterländer":
    "Large Münsterländers are hunting dogs that were bred for their versatility in tracking and retrieving game both on land and in water.",
  "Small Münsterländer":
    "The Small Münsterländer is a hunting dog that was originally bred in Germany to be a versatile gun dog that could track and retrieve game on land and in water.",
  Newfoundland:
    "Newfoundlands are also known for their gentle nature and are often used as therapy dogs.",
  Otterhound:
    "Otterhounds have a unique coat that is water-resistant and comes in a variety of colors, including black, brown, and tan.",
  "Caucasian Ovcharka":
    "The Caucasian Ovcharka is a large breed of dog that was originally bred in the Caucasus region to guard livestock.",
  Papillon:
    "Papillons are small dogs with large, butterfly-shaped ears, giving them their name (papillon means butterfly in French).",
  Pekinese:
    "Pekingese are small dogs that were originally bred to be lap dogs for Chinese nobility.",
  Pembroke:
    "Pembroke Welsh Corgis are a breed of herding dog that were originally bred in Wales to drive cattle.",
  "Miniature Pinscher":
    "The Miniature Pinscher is a small breed of dog that was originally bred in Germany to hunt rats.",
  Pitbull:
    "Pitbulls were originally bred for bull-baiting and bear-baiting, but have since been bred as loyal and loving family pets.",
  "German Longhaired Pointer":
    "The German Longhaired Pointer is a breed of hunting dog that was developed in Germany.",
  "German Shorthaired Pointer":
    "German Shorthaired Pointers are versatile hunting dogs that can hunt a variety of game on land and in water.",
  Pomeranian:
    "Pomeranians are a small breed of dog that were originally bred in Germany as lap dogs.",
  Poodle:
    "Poodles are a breed of dog that come in three sizes: standard, miniature, and toy.",
  "Miniature Poodle":
    "Miniature poodles are often used as therapy dogs because of their gentle and friendly nature.",
  "Toy Poodle":
    "Toy Poodles were originally bred in France to be companions to wealthy people and are still considered a symbol of elegance and luxury.",
  Pug: "Pugs are a very old breed, with roots dating back to ancient China.",
  Pyrenees:
    "Pyrenees were originally bred to protect flocks of sheep from predators, and they are still used for this purpose today.",
  Redbone:
    "Redbones are a type of coonhound, bred for their excellent hunting skills. They are named for their distinctive reddish coat.",
  "Chesapeake Retriever":
    "Chesapeake Retrievers have webbed feet that help them swim more efficiently, and their coat is oily and waterproof, which also helps them in the water.",
  "Curly Retriever":
    "The Curly Retriever was originally bred to retrieve game from water and have a dense, waterproof coat that helps them do so.",
  "Flatcoated Retriever":
    "Flatcoated Retrievers are a breed of gun dog that is known for its happy-go-lucky nature.",
  "Golden Retriever":
    "Golden Retrievers are one of the most popular dog breeds in the world, and it's easy to see why. They are friendly, loyal, and highly trainable.",
  "Rhodesian Ridgeback":
    "Rhodesian Ridgebacks are known for their distinctive 'ridge' of fur on their back, which grows in the opposite direction of their other fur.",
  Rottweiler:
    "Rottweilers are a large and powerful breed of dog that were originally bred as herding dogs. ",
  Saluki:
    "Salukis are one of the oldest dog breeds, with evidence of their existence dating back to ancient Egypt.",
  Samoyed:
    "Samoyeds were originally bred by the Samoyede people of Siberia to herd reindeer, pull sleds, and keep their owners warm.",
  Schipperke:
    "Schipperkes were originally bred in Belgium as watchdogs for canal boats.",
  "Giant Schnauzer":
    "Giant Schnauzers were originally bred in Germany as versatile working dogs, capable of herding, guarding, and even pulling carts.",
  "Miniature Schnauzer":
    "Miniature Schnauzers have distinctive eyebrows and beards that require regular grooming to keep them looking their best.",
  "English Setter":
    "English Setters have a unique 'belton' coat pattern, which is a mix of white and colored hairs that creates a speckled appearance.",
  "Gordon Setter":
    "Gordon Setters were originally bred in Scotland as bird dogs for hunting grouse and pheasants.",
  "Irish Setter":
    "Irish Setters are known for their beautiful, long red coat and graceful movement.",
  "English Sheepdog":
    "English Sheepdogs, also known as Old English Sheepdogs, were originally bred in England to drive sheep to market.",
  "Polish Tatra Sheepdog":
    "Polish Tatra Sheepdogs were originally bred to guard and protect flocks of sheep from predators, such as wolves and bears.",
  "Shetland Sheepdog":
    "The Shetland Sheepdog, also known as the Sheltie, is a herding dog breed that originated in the Shetland Islands of Scotland.",
  Shiba:
    "The Shiba is a Japanese dog breed that was originally used for hunting small game, such as birds and rabbits.",
  Shihtzu:
    "The Shihtzu is a small dog breed that originated in Tibet and was later developed in China.",
  "Blenheim Spaniel":
    "The Blenheim Spaniel is a variety of the Cavalier King Charles Spaniel breed, and is named after Blenheim Palace in England.",
  "Brittany Spaniel":
    "The Brittany Spaniel, also known simply as the Brittany, is a sporting dog breed that was originally bred in the Brittany region of France.",
  "Cavalier King Charles Spaniel":
    "The Cavalier King Charles Spaniel is a small toy dog breed that was named after King Charles II of England, who was said to have been very fond of the breed.",
  "Cocker Spaniel":
    "The Cocker Spaniel is a hunting dog breed that was originally bred for flushing out birds.",
  "Irish Spaniel":
    "The Irish Spaniel is a rare dog breed that was originally developed in Ireland for hunting waterfowl.",
  "Japanese Spaniel":
    "The Japanese Spaniel, also known as the Chin, is a toy dog breed that originated in China and was later developed in Japan.",
  "Sussex Spaniel":
    "The Sussex Spaniel is a rare dog breed that was developed in England for hunting birds.",
  "Welsh Spaniel":
    "The Welsh Spaniel, also known as the Welsh Springer Spaniel, is a hunting dog breed that originated in Wales.",
  "Springer Spaniel":
    "The Springer Spaniel is a sporting dog breed that was originally bred for flushing out birds.",
  "St Bernard":
    "The St Bernard breed was originally bred to assist with rescue efforts in the Swiss Alps.",
  Tamaskan:
    "Tamaskans are a relatively new breed, created in Finland in the 1980s to resemble wolves.",
  "American Terrier":
    "When properly socialized and trained, American Terriers can make loyal and loving pets, despite their reputation as aggressive dogs.",
  "Australian Terrier":
    "The Australian Terrier is one of the smallest terrier breeds, typically weighing between 12-14 pounds.",
  "Bedlington Terrier":
    "The Bedlington Terrier is known for its unique lamb-like appearance, which is a result of its curly, woolly coat.",
  "Border Terrier":
    "The Border Terrier is a small, scrappy terrier breed that was originally bred for hunting foxes and rodents.",
  "Boston Terrier":
    "Boston Terriers are known for their tuxedo-like coat, which is usually black and white or brindle and white.",
  "Cairn Terrier":
    "The Cairn Terrier is a small but sturdy terrier breed that originated in Scotland.",
  "Dandie Terrier":
    "The Dandie Dinmont Terrier is named after a character in a Sir Walter Scott novel.",
  "English Bull Terrier":
    "The English Bull Terrier has a distinctive egg-shaped head and are known for their muscular build.",
  "Fox Terrier":
    "The Fox Terrier is a small but energetic breed that was originally developed in England for hunting foxes and other small game.",
  "Irish Terrier":
    "The Irish Terrier was used in World War I to carry messages between soldiers.",
  "Kerryblue Terrier":
    "A Kerryblue Terrier's coat starts out black when they're puppies, but as they mature it gradually turns to a unique blue-gray color.",
  "Lakeland Terrier":
    "The Lakeland Terrier was originally used for fox hunting in the Lake District of England.",
  "Norfolk Terrier":
    "The Norfolk Terrier is one of the smallest working terrier breeds.",
  "Norwich Terrier":
    "The Norwich Terrier has a thick double coat that is weather-resistant and provides excellent insulation in cold weather.",
  "Patterdale Terrier":
    "The Patterdale Terrier was originally bred in the Lake District of England for hunting small game and vermin.",
  "Jack Russell":
    "The Jack Russell Terrier was originally bred in England for fox hunting.",
  "Scottish Terrier":
    "The Scottish Terrier is known for their distinctive beard and eyebrows.",
  "Sealyham Terrier":
    "The Sealyham Terrier was originally bred in Wales for hunting badgers, otters, and foxes.",
  "Silky Terrier":
    "The Silky Terrier is a small breed that was originally bred in Australia.",
  "Tibetan Terrier":
    "The Tibetan Terrier was originally kept by Tibetan monks as a companion and watchdog.",
  "Toy Terrier":
    "The Toy Terrier, also known as the Toy Manchester Terrier, is one of the smallest terrier breeds.",
  "Welsh Terrier":
    "The Welsh Terrier is a small breed that was originally used for hunting badgers, foxes, and other small game in Wales.",
  "Westhighland Terrier":
    "The West Highland Terrier, or 'Westie,' was originally bred in Scotland for hunting small game.",
  "Wheaten Terrier":
    "The Wheaten Terrier has a soft, silky coat that can range from pale beige to golden brown.",
  "Yorkshire Terrier":
    "The Yorkshire Terrier was originally used for catching rats in textile mills in England.",
  Tervuren:
    "The Tervuren is one of four varieties of Belgian Shepherd, and is named after the Belgian village of Tervuren.",
  Vizsla:
    "The Vizsla is an excellent athlete and has a natural talent for running, jumping, and swimming.",
  "Spanish Waterdog":
    "The Spanish Waterdog is an ancient breed that was developed in Spain for herding and retrieving livestock, as well as for hunting.",
  Weimaraner:
    "The Weimaraner is known for its striking silver-gray coat, and is sometimes called the 'gray ghost' because of its color and stealthy hunting abilities.",
  Whippet:
    "Despite their small size, Whippets are incredibly fast and agile, and can reach speeds of up to 35 miles per hour.",
  "Irish Wolfhound":
    "The Irish Wolfhound is one of the tallest dog breeds in the world, with males reaching heights of up to 32 inches at the shoulder and weighing up to 180 pounds.",
};

export default singleBreedFacts