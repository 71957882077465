import { AutocompleteDropdown } from 'react-native-autocomplete-dropdown'

export default function AutoComplete(props) {
    return (
        <AutocompleteDropdown
            inputHeight={20}
            clearOnFocus={false}
            showChevron={false}
            textInputProps={{
                editable: false,
                style: {
                    backgroundColor: '#E7E7E7',
                    outline: 'none'
                }
            }}
        />
    )
}