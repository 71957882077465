import { useState, useEffect } from "react"
import { Image, Pressable, Share, StyleSheet, Text, View } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import * as WebBrowser from 'expo-web-browser'

import singleBreedFacts from '../data/singleBreedFacts'

import app from '../firebaseConfig'
import { getAnalytics, logEvent } from "firebase/analytics"
const analytics = getAnalytics(app)

export default function PostGameModal(props) {
    const { singleBreed, gameWon, breed, breeds, imageOwner, imageURL, imageResizeMode } = props

    const [factFromFirestore, setFactFromFirestore] = useState("Fetching today's fact...");

    const shareStats_SB = async () => {
        try {
            const dogNumberLS = JSON.parse(await AsyncStorage.getItem('dogNumber'))
            const breedsCorrectObjectLS = JSON.parse(await AsyncStorage.getItem('sbBreedsCorrect'))
            const row1 = `${breedsCorrectObjectLS.sbBreed1Correct === true ? '🐶' : breedsCorrectObjectLS.sbBreed1Correct === false ? '⬜' : '▫️'}`
            const row2 = `${breedsCorrectObjectLS.sbBreed2Correct === true ? '🐶' : breedsCorrectObjectLS.sbBreed2Correct === false ? '⬜️' : '▫️'}`
            const row3 = `${breedsCorrectObjectLS.sbBreed3Correct === true ? '🐶' : breedsCorrectObjectLS.sbBreed3Correct === false ? '⬜️' : '▫️'}`
            const row4 = `${breedsCorrectObjectLS.sbBreed4Correct === true ? '🐶' : breedsCorrectObjectLS.sbBreed4Correct === false ? '⬜️' : '▫️'}`
            const row5 = `${breedsCorrectObjectLS.sbBreed5Correct === true ? '🐶' : breedsCorrectObjectLS.sbBreed5Correct === false ? '⬜️' : '▫️'}`

            const numOfTrues = Object.values(breedsCorrectObjectLS).reduce((a, item) => a + item, 0)
            await Share.share({
                message: `Do you know the breed of this dog?\n\nPlay the dog guessing game at: https://doggle.app \n\nDOGGLE #${dogNumberLS}\n${row1}${row2}${row3}${row4}${row5}`,
            })
            // if (result.action === Share.sharedAction) {
            //     if (result.activityType) {
            //         // shared with activity type of result.activityType
            //     } else {
            //         // shared
            //     }
            if (result.action === Share.dismissedAction) {
                return
            }
            logEvent(analytics, 'share_single_breed_(modal)')
        } catch (error) {
            console.log(error)
            logEvent(analytics, 'ERROR_share_single_breed_(modal)')
            if (error.message == 'Share is not supported in this browser') {
                alert('Sorry, sharing not supported on this browser/device')
            }
        }
    }

    const shareStats_MB = async () => {
        try {
            const dogNumberLS = JSON.parse(await AsyncStorage.getItem('dogNumber'))
            const breedsCorrectObjectLS = JSON.parse(await AsyncStorage.getItem('mbBreedsCorrect'))
            const row1 = `${(breedsCorrectObjectLS.mbBreedA1Correct || breedsCorrectObjectLS.mbBreedB1Correct) === true ? '🐶' : (breedsCorrectObjectLS.mbBreedA1Correct === false || breedsCorrectObjectLS.mbBreedB1Correct === false) ? '⬜' : '▫️'}`
            const row2 = `${(breedsCorrectObjectLS.mbBreedA2Correct || breedsCorrectObjectLS.mbBreedB2Correct) === true ? '🐶' : (breedsCorrectObjectLS.mbBreedA2Correct === false || breedsCorrectObjectLS.mbBreedB2Correct === false) ? '⬜' : '▫️'}`
            const row3 = `${(breedsCorrectObjectLS.mbBreedA3Correct || breedsCorrectObjectLS.mbBreedB3Correct) === true ? '🐶' : (breedsCorrectObjectLS.mbBreedA3Correct === false || breedsCorrectObjectLS.mbBreedB3Correct === false) ? '⬜' : '▫️'}`
            const row4 = `${(breedsCorrectObjectLS.mbBreedA4Correct || breedsCorrectObjectLS.mbBreedB4Correct) === true ? '🐶' : (breedsCorrectObjectLS.mbBreedA4Correct === false || breedsCorrectObjectLS.mbBreedB4Correct === false) ? '⬜' : '▫️'}`
            const row5 = `${(breedsCorrectObjectLS.mbBreedA5Correct || breedsCorrectObjectLS.mbBreedB5Correct) === true ? '🐶' : (breedsCorrectObjectLS.mbBreedA5Correct === false || breedsCorrectObjectLS.mbBreedB5Correct === false) ? '⬜' : '▫️'}`

            const numOfTrues = Object.values(breedsCorrectObjectLS).reduce((a, item) => a + item, 0)
            await Share.share({
                message: `Do you know the breed of this dog?\n\nPlay the dog guessing game at: https://doggle.app \n\nDOGGLE #${dogNumberLS}\n${numOfTrues}/2\n${row1}${row2}${row3}${row4}${row5}`,
            })
            // if (result.action === Share.sharedAction) {
            //     if (result.activityType) {
            //         // shared with activity type of result.activityType
            //     } else {
            //         // shared
            //     }
            if (result.action === Share.dismissedAction) {
                return
            }
            logEvent(analytics, 'share_mixed_breed_(modal)')
        } catch (error) {
            console.log(error)
            logEvent(analytics, 'ERROR_share_mixed_breed_(modal)')
            if (error.message == 'Share is not supported in this browser') {
                alert('Sorry, sharing not supported on this browser/device')
            }
        }
    }

    useEffect(() => {
        logEvent(analytics, 'SCREEN_VIEW_modal')
        getAndSetFact()
    }, [])

    const getAndSetFact = async () => {
        const factFromLS = JSON.parse(await AsyncStorage.getItem("fact"))
        console.log('FACT: ', factFromLS)
        setFactFromFirestore(factFromLS)
    }

    const openSubmitDogForm = async () => {
        logEvent(analytics, 'open_submit_dog_form_(modal)')
        await WebBrowser.openBrowserAsync('https://tally.so/r/waQJ9E')
    }

    return (
        <View style={styles.container}>

            <Text style={{ marginBottom: 10, fontWeight: 600, fontSize: 18, alignSelf: 'center' }}>{gameWon ? '🐶 WOOF WOOF 🐶' : 'Better luck tomorrow!'}</Text>


            <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                    <Image
                        style={{ ...styles.imageBox, backgroundColor: gameWon === true ? '#CBF2CA' : '#EFBDBD' }}
                        resizeMode={imageResizeMode}
                        source={{ uri: imageURL }}
                    />
                    {
                        imageOwner.length > 1 ?
                            <View style={styles.copyrightMessage}>
                                <Text style={{ color: "white", fontSize: 10 }}>{imageOwner[1]} the dog, submitted by {imageOwner[0]}</Text>
                            </View>
                            : null
                    }
                </View>
            </View>

            <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                {
                    singleBreed === true ?
                        <Text style={{ marginVertical: 10, fontWeight: 'bold', fontSize: 24, textAlign: 'center' }}>
                            {breed === 'German Shepherd (Alsatian)' ? 'German Shepherd' : breed}
                        </Text>
                        // :
                        // nickname
                        //     ?
                        //     <>
                        //         <Text style={{ marginTop: 10, fontWeight: 'bold', fontSize: 24 }}>{nickname}</Text>
                        //         <Text style={{ marginTop: 3, fontSize: 16 }}>{breeds[0]} & {breeds[1]}</Text>
                        //     </>
                        :
                        <Text style={{ marginVertical: 10, fontWeight: 'bold', fontSize: 24, textAlign: 'center' }}>
                            {breeds[0] === 'German Shepherd (Alsatian)' ? 'German Shepherd' : breeds[0]} & {breeds[1] === 'German Shepherd (Alsatian)' ? 'German Shepherd' : breeds[1]}
                        </Text>
                }

                <Pressable onPress={singleBreed ? shareStats_SB : shareStats_MB} style={styles.buttonShare}>
                    <Image source={require('../assets/share-white.png')} resizeMode="contain" style={{ width: 20, height: 20, marginRight: 10 }} />
                    <Text style={{ color: 'white', fontWeight: 600, fontSize: 24, paddingBottom: 2 }}>Share</Text>
                </Pressable>

            </View>

            <View style={{ marginVertical: 25, alignItems: 'center', justifyContent: 'center', padding: 10 }}>
                {
                    singleBreed === true ?
                        <Text style={{ textAlign: 'center', fontSize: 14, fontStyle: 'italic' }}>{singleBreedFacts[breed]}</Text>
                        :
                        <Text style={{ textAlign: 'center', fontSize: 14, fontStyle: 'italic' }}>Daily DOGGLE fact: {factFromFirestore}</Text>
                }
            </View>

            <View style={{ backgroundColor: "grey", height: 1, width: '85%', maxWidth: 600 }} />

            <View style={{ marginVertical: 10, alignItems: 'center', justifyContent: 'center' }}>
                <Text style={{ fontSize: 16 }}>Want your dog in the game?</Text>
                <Pressable onPress={openSubmitDogForm} style={styles.buttonFeatureDog}>
                    <Text style={{ color: 'white', fontWeight: 600, fontSize: 17 }}>Feature my dog</Text>
                </Pressable>
            </View>

        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        width: '95%',
        marginBottom: 15,
        alignItems: 'center',
        justifyContent: 'center'
    },
    buttonShare: {
        flexDirection: 'row',
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#2C41FF",
        borderRadius: 30,
        paddingVertical: 10,
        paddingHorizontal: 20,
    },
    buttonFeatureDog: {
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#2C41FF",
        borderColor: "gray",
        marginTop: 5,
        borderRadius: 30,
        paddingVertical: 5,
        paddingHorizontal: 15,
    },
    imageBox: {
        height: 210,
        width: 240,
        borderRadius: 5,
        // background: require('../assets/wallpaper.png')
        backgroundColor: '#E7E7E7',
    },
    copyrightMessage: {
        flexDirection: "row",
        marginBottom: 5,
        position: "absolute",
        bottom: 0,
        alignItems: "center",
        backgroundColor: "rgba(0,0,0,0.5)",
        padding: 5,
        borderRadius: 7,
    },
})