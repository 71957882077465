import useCountDown from "./useCountDown"
import { View, Text } from 'react-native'

const CountdownTimer = ({ targetDate }) => {
  const [hours, minutes, seconds] = useCountDown(targetDate)

  if (hours + minutes + seconds <= 0) {
    return <Text>Expired!</Text>
  } else {
    return (

      <Text style={{ fontVariant: ['tabular-nums'], textAlign: 'center', fontSize: 22, letterSpacing: 2 }}>
        {hours < 10 ? `0${hours}` : hours}
        :
        {minutes < 10 ? `0${minutes}` : minutes}
        :
        {seconds < 10 ? `0${seconds}` : seconds}
      </Text>
    )
  }
}

export default CountdownTimer