import { useEffect, useState } from "react"
import { ActivityIndicator, Dimensions, Image, Modal, Pressable, StyleSheet, Text, TouchableOpacity, View } from "react-native"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { showMessage } from "react-native-flash-message"
import * as WebBrowser from 'expo-web-browser'

import PostGameModal from "../screens/PostGameModal"

import app from '../firebaseConfig'
import { getAnalytics, logEvent } from "firebase/analytics"
const analytics = getAnalytics(app)

import AutoCompleteSB_1 from "./AutoComplete"
import AutoCompleteSB_2 from "./AutoComplete"
import AutoCompleteSB_3 from "./AutoComplete"
import AutoCompleteSB_4 from "./AutoComplete"
import AutoCompleteSB_5 from "./AutoComplete"
import AutoCompleteSB_Empty from "./AutoCompleteEmpty"
import PostGameMessage from "./PostGameMessage"

const screenWidth = Dimensions.get('screen').width
const deviceSize = screenWidth > 415 ? 'large' : screenWidth > 380 ? 'medium' : 'small'

export default function SingleBreed(props) {
  const { breed, imageURL, imageOwner, nickname, imageResizeMode } = props.data
  const [isLoading, setLoading] = useState(true)

  const [lastGuessRound, setLastGuessRound] = useState(0)
  const [gameWon, setGameWon] = useState(null)
  const [favourited, setFavourited] = useState(false)
  // const [showCopyright, setShowCopyright] = useState(false)

  const [statsGamesPlayed, setStatsGamesPlayed] = useState(null)
  const [statsGuessDistribution, setStatsGuessDistribution] = useState([])
  const [statsBreedsCorrectlyGuessed, setStatsBreedsCorrectlyGuessed] = useState(null)

  const [modalVisible, setModalVisible] = useState(false)
  const [revealAnswerModalVisible, setRevealAnswerModalVisible] = useState(false)
  const closeModal = () => setModalVisible(false)

  const [breed1, setBreed1] = useState(null)
  const [breed2, setBreed2] = useState(null)
  const [breed3, setBreed3] = useState(null)
  const [breed4, setBreed4] = useState(null)
  const [breed5, setBreed5] = useState(null)

  const [breed1Correct, setBreed1Correct] = useState(null)
  const [breed2Correct, setBreed2Correct] = useState(null)
  const [breed3Correct, setBreed3Correct] = useState(null)
  const [breed4Correct, setBreed4Correct] = useState(null)
  const [breed5Correct, setBreed5Correct] = useState(null)

  const handleSelect1 = (breed) => { setBreed1(breed) }
  const handleSelect2 = (breed) => { setBreed2(breed) }
  const handleSelect3 = (breed) => { setBreed3(breed) }
  const handleSelect4 = (breed) => { setBreed4(breed) }
  const handleSelect5 = (breed) => { setBreed5(breed) }

  useEffect(() => {
    getDataFromLS()
    setLoading(false)
    logEvent(analytics, 'SCREEN_VIEW_single_breed')
  }, [])

  useEffect(() => {
    lastGuessRound === 1
      ? saveFirstRoundGuessesToLS(breed1) : lastGuessRound === 2
        ? saveSecondRoundGuessesToLS(breed2) : lastGuessRound === 3
          ? saveThirdRoundGuessesToLS(breed3) : lastGuessRound === 4
            ? saveFourthRoundGuessesToLS(breed4) : lastGuessRound === 5
              ? saveFifthRoundGuessesToLS(breed5) : null

    lastGuessRound > 4 && gameWon !== true ? setGameWon(false) : null
  }, [lastGuessRound])

  useEffect(() => {
    if (gameWon === true) {
      saveSBWinToLS(true)
      saveStatsToLS()
      setTimeout(() => setModalVisible(true), 1500)
    } else if (gameWon === false) {
      saveSBWinToLS(false)
      saveStatsToLS()
      setTimeout(() => setModalVisible(true), 1500)
    }
  }, [gameWon])

  useEffect(() => {
    if (props.recentlyUnfaved === true) {
      setFavourited(false)
      props.setRecentlyUnfaved(false)
    }
  }, [props.recentlyUnfaved])

  // const showCopyrightInfo = () => {
  //   setShowCopyright((prevState) => !prevState)
  //   logEvent(analytics, 'show_copyright')
  // }

  // const hideCopyrightInfo = () => {
  //   setShowCopyright((prevState) => !prevState)
  //   logEvent(analytics, 'hide_copyright')
  // }

  const openImageOwner = async () => {
    await WebBrowser.openBrowserAsync(imageOwner[0])
    logEvent(analytics, 'open_image_owner')
  }

  const saveStatsToLS = async () => {
    const statsTimestamp = await AsyncStorage.getItem("sbStatsLastUpdated")

    const today = new Date().setHours(0, 0, 0, 0)
    const statsTsAsDay = new Date(Number(statsTimestamp)).setHours(0, 0, 0, 0)

    if (statsTimestamp === null || statsTsAsDay !== today) {
      const sbBreedsCorrect = {
        sbBreed1Correct: breed1Correct,
        sbBreed2Correct: breed2Correct,
        sbBreed3Correct: breed3Correct,
        sbBreed4Correct: breed4Correct,
        sbBreed5Correct: breed5Correct,
      }

      const numOfTrues = Object.values(sbBreedsCorrect).reduce(
        (a, item) => a + item,
        0
      )

      if (numOfTrues === 0) {
        await AsyncStorage.setItem(
          "sbStatsGuessDistribution",
          JSON.stringify([
            statsGuessDistribution[0] + 1,
            statsGuessDistribution[1],
          ])
        )
      } else if (numOfTrues === 1) {
        await AsyncStorage.setItem(
          "sbStatsGuessDistribution",
          JSON.stringify([
            statsGuessDistribution[0],
            statsGuessDistribution[1] + 1,
          ])
        )
      }

      await AsyncStorage.setItem(
        "sbStatsBreedsCorrectlyGuessed",
        JSON.stringify(statsBreedsCorrectlyGuessed + numOfTrues)
      )
      await AsyncStorage.setItem(
        "sbStatsGamesPlayed",
        JSON.stringify(statsGamesPlayed + 1)
      )
      await AsyncStorage.setItem(
        "sbStatsLastUpdated",
        JSON.stringify(Date.now())
      )

      logEvent(analytics, 'SB_game_finished')

      numOfTrues === 1 ? logEvent(analytics, 'SB_game_win') : logEvent(analytics, 'SB_game_lost')

    }
  }

  const clearLS = async () => {
    try {
      await AsyncStorage.removeItem("sbGameWon")
      await AsyncStorage.removeItem("sbLastGuessRound")
      await AsyncStorage.removeItem("sbLastTimestamp")
      await AsyncStorage.removeItem("sbBreeds")
      await AsyncStorage.removeItem("sbBreedsCorrect")
      await AsyncStorage.removeItem("sbStatsLastUpdated")
    } catch (error) {
      console.log(error)
    }
  }

  const getDataFromLS = async () => {
    const gameWonLS = JSON.parse(await AsyncStorage.getItem("sbGameWon"))
    const lastGuessRoundLS = JSON.parse(
      await AsyncStorage.getItem("sbLastGuessRound")
    )
    const lastTsLS = JSON.parse(await AsyncStorage.getItem("sbLastTimestamp"))
    const faveImagesLS = JSON.parse(await AsyncStorage.getItem("faves"))

    //get Stats
    const statsGamesPlayedLS = JSON.parse(
      await AsyncStorage.getItem("sbStatsGamesPlayed")
    )
    const statsBreedsCorrectlyGuessedLS = JSON.parse(
      await AsyncStorage.getItem("sbStatsBreedsCorrectlyGuessed")
    )
    const statsGuessDistributionLS = JSON.parse(
      await AsyncStorage.getItem("sbStatsGuessDistribution")
    )

    statsGamesPlayedLS === null
      ? setStatsGamesPlayed(0)
      : setStatsGamesPlayed(statsGamesPlayedLS)
    statsBreedsCorrectlyGuessedLS === null
      ? setStatsBreedsCorrectlyGuessed(0)
      : setStatsBreedsCorrectlyGuessed(statsBreedsCorrectlyGuessedLS)
    statsGuessDistributionLS === null
      ? setStatsGuessDistribution([0, 0, 0])
      : setStatsGuessDistribution(statsGuessDistributionLS)

    if (lastTsLS === null) {
      setGameWon(null)
      faveImagesLS !== null &&
        faveImagesLS.includes(imageURL) &&
        setFavourited(true)
      clearLS()
      return
    }

    try {
      const today = new Date().setHours(0, 0, 0, 0)
      const lastPlayedDay = new Date(Number(lastTsLS)).setHours(0, 0, 0, 0)

      // If lastPlayedDay is not today, then clear localStorage & reset game/scores (as user is playing for first time today)
      // If it is today, then keep current scores
      if (lastPlayedDay !== today) {
        setGameWon(null)
        faveImagesLS !== null &&
          faveImagesLS.includes(imageURL) &&
          setFavourited(true)
        clearLS()
      } else {
        const breeds = JSON.parse(await AsyncStorage.getItem("sbBreeds"))
        const breedsCorrect = JSON.parse(
          await AsyncStorage.getItem("sbBreedsCorrect")
        )

        setBreed1(breeds.sbBreed1 === undefined ? null : breeds.sbBreed1)
        setBreed2(breeds.sbBreed2 === undefined ? null : breeds.sbBreed2)
        setBreed3(breeds.sbBreed3 === undefined ? null : breeds.sbBreed3)
        setBreed4(breeds.sbBreed4 === undefined ? null : breeds.sbBreed4)
        setBreed5(breeds.sbBreed5 === undefined ? null : breeds.sbBreed5)
        setBreed1Correct(
          breedsCorrect.sbBreed1Correct === undefined
            ? null
            : breedsCorrect.sbBreed1Correct
        )
        setBreed2Correct(
          breedsCorrect.sbBreed2Correct === undefined
            ? null
            : breedsCorrect.sbBreed2Correct
        )
        setBreed3Correct(
          breedsCorrect.sbBreed3Correct === undefined
            ? null
            : breedsCorrect.sbBreed3Correct
        )
        setBreed4Correct(
          breedsCorrect.sbBreed4Correct === undefined
            ? null
            : breedsCorrect.sbBreed4Correct
        )
        setBreed5Correct(
          breedsCorrect.sbBreed5Correct === undefined
            ? null
            : breedsCorrect.sbBreed5Correct
        )

        setGameWon(gameWonLS)
        faveImagesLS !== null &&
          faveImagesLS.includes(imageURL) &&
          setFavourited(true)
        !lastGuessRoundLS
          ? setLastGuessRound(0)
          : setLastGuessRound(Number(lastGuessRoundLS))
      }
    } catch (error) {
      console.log(error)
    }
  }

  const saveFirstRoundGuessesToLS = async () => {
    try {
      const sbBreeds = {
        sbBreed1: breed1,
      }
      const sbBreedsCorrect = {
        sbBreed1Correct: breed1Correct,
      }
      await AsyncStorage.setItem("sbBreeds", JSON.stringify(sbBreeds))
      await AsyncStorage.setItem(
        "sbBreedsCorrect",
        JSON.stringify(sbBreedsCorrect)
      )
      await AsyncStorage.setItem("sbLastTimestamp", Date.now())
      await AsyncStorage.setItem("sbLastGuessRound", 1)
    } catch (e) {
      console.log(e)
    }
  }

  const saveSecondRoundGuessesToLS = async () => {
    try {
      const sbBreeds = {
        sbBreed1: breed1,
        sbBreed2: breed2,
      }
      const sbBreedsCorrect = {
        sbBreed1Correct: breed1Correct,
        sbBreed2Correct: breed2Correct,
      }
      await AsyncStorage.setItem("sbBreeds", JSON.stringify(sbBreeds))
      await AsyncStorage.setItem(
        "sbBreedsCorrect",
        JSON.stringify(sbBreedsCorrect)
      )
      await AsyncStorage.setItem("sbLastTimestamp", Date.now())
      await AsyncStorage.setItem("sbLastGuessRound", 2)
    } catch (e) {
      console.log(e)
    }
  }

  const saveThirdRoundGuessesToLS = async () => {
    try {
      const sbBreeds = {
        sbBreed1: breed1,
        sbBreed2: breed2,
        sbBreed3: breed3,
      }
      const sbBreedsCorrect = {
        sbBreed1Correct: breed1Correct,
        sbBreed2Correct: breed2Correct,
        sbBreed3Correct: breed3Correct,
      }

      await AsyncStorage.setItem("sbBreeds", JSON.stringify(sbBreeds))
      await AsyncStorage.setItem(
        "sbBreedsCorrect",
        JSON.stringify(sbBreedsCorrect)
      )
      await AsyncStorage.setItem("sbLastTimestamp", Date.now())
      await AsyncStorage.setItem("sbLastGuessRound", 3)
    } catch (e) {
      console.log(e)
    }
  }

  const saveFourthRoundGuessesToLS = async () => {
    try {
      const sbBreeds = {
        sbBreed1: breed1,
        sbBreed2: breed2,
        sbBreed3: breed3,
        sbBreed4: breed4,
      }
      const sbBreedsCorrect = {
        sbBreed1Correct: breed1Correct,
        sbBreed2Correct: breed2Correct,
        sbBreed3Correct: breed3Correct,
        sbBreed4Correct: breed4Correct,
      }

      await AsyncStorage.setItem("sbBreeds", JSON.stringify(sbBreeds))
      await AsyncStorage.setItem(
        "sbBreedsCorrect",
        JSON.stringify(sbBreedsCorrect)
      )
      await AsyncStorage.setItem("sbLastTimestamp", Date.now())
      await AsyncStorage.setItem("sbLastGuessRound", 4)
    } catch (e) {
      console.log(e)
    }
  }

  const saveFifthRoundGuessesToLS = async () => {
    try {
      const sbBreeds = {
        sbBreed1: breed1,
        sbBreed2: breed2,
        sbBreed3: breed3,
        sbBreed4: breed4,
        sbBreed5: breed5,
      }
      const sbBreedsCorrect = {
        sbBreed1Correct: breed1Correct,
        sbBreed2Correct: breed2Correct,
        sbBreed3Correct: breed3Correct,
        sbBreed4Correct: breed4Correct,
        sbBreed5Correct: breed5Correct,
      }

      await AsyncStorage.setItem("sbBreeds", JSON.stringify(sbBreeds))
      await AsyncStorage.setItem(
        "sbBreedsCorrect",
        JSON.stringify(sbBreedsCorrect)
      )
      await AsyncStorage.setItem("sbLastTimestamp", Date.now())
      await AsyncStorage.setItem("sbLastGuessRound", 5)
    } catch (e) {
      console.log(e)
    }
  }

  const saveSBWinToLS = async (value) => {
    try {
      await AsyncStorage.setItem("sbGameWon", value)
    } catch (e) {
      console.log(e)
    }
  }

  const saveToFaves = async () => {
    try {
      const result = await AsyncStorage.getItem("faves")
      const id = [imageURL]
      if (result !== null) {
        let newIds = JSON.parse(result).concat(id)
        AsyncStorage.setItem("faves", JSON.stringify(newIds))
        setFavourited(true)
      } else {
        AsyncStorage.setItem("faves", JSON.stringify(id))
        setFavourited(true)
      }
    } catch (e) {
      console.log(e)
    }
    logEvent(analytics, 'save_to_favourites')
  }

  const removeFromFaves = async () => {
    try {
      const result = await AsyncStorage.getItem("faves")
      const id = imageURL
      if (result !== null) {
        let newIds = JSON.parse(result).filter((item) => item !== id)
        AsyncStorage.setItem("faves", JSON.stringify(newIds))
        setFavourited(false)
      } else {
        setFavourited(false)
      }
    } catch (e) {
      console.log(e)
    }
    logEvent(analytics, 'remove_from_favourites')
  }

  const toggleFaves = () => {
    !favourited ? saveToFaves() : removeFromFaves()
  }

  const checkGuessFull = () => {
    if (lastGuessRound === 0) {
      if (breed1 === null) {
        showMessage({ message: "Please search & select a breed", type: "info", style: { textAlign: 'center' } })
        return
      }
      if (breed1 === breed) {
        setBreed1Correct(true)
        setGameWon(true)
        setLastGuessRound(lastGuessRound + 1)
        logEvent(analytics, 'SB_game_started')
        logEvent(analytics, 'SB_guess_1_correct')
        logEvent(analytics, 'SB_game_won')
        logEvent(analytics, 'SB_game_finished')
        return
      }
      setBreed1Correct(false)
      setLastGuessRound(lastGuessRound + 1)
      logEvent(analytics, 'SB_game_started')
      logEvent(analytics, 'SB_guess_1_incorrect')
      return
    }

    if (lastGuessRound === 1) {
      if (breed2 === null) {
        showMessage({ message: "Please search & select a breed", type: "info", style: { textAlign: 'center' } })
        return
      }
      if (breed2 === breed1) {
        showMessage({ message: "Please ensure breed is not the same as a previous guess", type: "info", style: { textAlign: 'center' } })
        return
      }
      if (breed2 === breed) {
        setBreed2Correct(true)
        setGameWon(true)
        setLastGuessRound(lastGuessRound + 1)
        logEvent(analytics, 'SB_guess_2_correct')
        logEvent(analytics, 'SB_game_won')
        logEvent(analytics, 'SB_game_finished')
        return
      }
      setBreed2Correct(false)
      setLastGuessRound(lastGuessRound + 1)
      logEvent(analytics, 'SB_guess_2_incorrect')
      return
    }

    if (lastGuessRound === 2) {
      if (breed3 === null) {
        showMessage({ message: "Please search & select a breed", type: "info", style: { textAlign: 'center' } })
        return
      }
      if (breed3 === breed2 || breed3 === breed1) {
        showMessage({ message: "Please ensure breed is not the same as a previous guess", type: "info", style: { textAlign: 'center' } })
        return
      }
      if (breed3 === breed) {
        setBreed3Correct(true)
        setGameWon(true)
        setLastGuessRound(lastGuessRound + 1)
        logEvent(analytics, 'SB_guess_3_correct')
        logEvent(analytics, 'SB_game_won')
        logEvent(analytics, 'SB_game_finished')
        return
      }
      setBreed3Correct(false)
      setLastGuessRound(lastGuessRound + 1)
      logEvent(analytics, 'SB_guess_3_incorrect')
    }

    if (lastGuessRound === 3) {
      if (breed4 === null) {
        showMessage({ message: "Please search & select a breed", type: "info", style: { textAlign: 'center' } })
        return
      }
      if (breed4 === breed3 || breed4 === breed2 || breed4 === breed1) {
        showMessage({ message: "Please ensure breed is not the same as a previous guess", type: "info", style: { textAlign: 'center' } })
        return
      }
      if (breed4 === breed) {
        setBreed4Correct(true)
        setGameWon(true)
        setLastGuessRound(lastGuessRound + 1)
        logEvent(analytics, 'SB_guess_4_correct')
        logEvent(analytics, 'SB_game_won')
        logEvent(analytics, 'SB_game_finished')
        return
      }
      setBreed4Correct(false)
      setLastGuessRound(lastGuessRound + 1)
      logEvent(analytics, 'SB_guess_4_incorrect')
    }

    if (lastGuessRound === 4) {
      if (breed5 === null) {
        showMessage({ message: "Please search & select a breed", type: "info", style: { textAlign: 'center' } })
        return
      }
      if (breed5 === breed4 || breed5 === breed3 || breed5 === breed2 || breed5 === breed1) {
        showMessage({ message: "Please ensure breed is not the same as a previous guess", type: "info", style: { textAlign: 'center' } })
        return
      }
      if (breed5 === breed) {
        setBreed5Correct(true)
        setGameWon(true)
        setLastGuessRound(lastGuessRound + 1)
        logEvent(analytics, 'SB_guess_5_correct')
        logEvent(analytics, 'SB_game_won')
        logEvent(analytics, 'SB_game_finished')
        return
      }
      setBreed5Correct(false)
      setLastGuessRound(lastGuessRound + 1)
      logEvent(analytics, 'SB_guess_5_incorrect')
      logEvent(analytics, 'SB_game_lost')
      logEvent(analytics, 'SB_game_finished')
    }
    logEvent(analytics, 'submit_guess_single_breed')
  }

  const openSubmitDogForm = async () => {
    logEvent(analytics, 'open_submit_dog_form')
    await WebBrowser.openBrowserAsync('https://tally.so/r/waQJ9E')
  }

  const revealAnswer = () => {
    logEvent(analytics, 'single_breed_reveal_answer')
    if (lastGuessRound === 0) {
      setBreed1('-')
      setBreed2('-')
      setBreed3('-')
      setBreed4('-')
      setBreed5('-')
      setBreed1Correct(false)
      setBreed2Correct(false)
      setBreed3Correct(false)
      setBreed4Correct(false)
      setBreed5Correct(false)
      setLastGuessRound(5)
    } else if (lastGuessRound === 1) {
      setBreed2('-')
      setBreed3('-')
      setBreed4('-')
      setBreed5('-')
      setBreed2Correct(false)
      setBreed3Correct(false)
      setBreed4Correct(false)
      setBreed5Correct(false)
      setLastGuessRound(5)

    } else if (lastGuessRound === 2) {
      setBreed3('-')
      setBreed4('-')
      setBreed5('-')
      setBreed3Correct(false)
      setBreed4Correct(false)
      setBreed5Correct(false)
      setLastGuessRound(5)

    } else if (lastGuessRound === 3) {
      setBreed4('-')
      setBreed5('-')
      setBreed4Correct(false)
      setBreed5Correct(false)
      setLastGuessRound(5)

    } else if (lastGuessRound === 4) {
      setBreed5('-')
      setBreed5Correct(false)
      setLastGuessRound(5)

    }
  }

  return (
    <View style={styles.container}>
      {isLoading === true ? (
        <ActivityIndicator size="large" />
      ) : (
        // <View style={styles.imageContainer}>
        <View>
          <Image
            style={styles.imageBox}
            resizeMode={imageResizeMode !== undefined ? imageResizeMode : "cover"}
            source={{
              uri: imageURL,
            }}
          />

          <View style={styles.imageButtons}>
            {/* {showCopyright ? (
              <TouchableOpacity
                onPress={() => hideCopyrightInfo()}
              >
                <Image
                  style={{ height: 20, width: 20, marginVertical: 2 }}
                  resizeMode="contain"
                  source={require("../assets/copyright-solid.png")}
                />
              </TouchableOpacity>
            ) : (
              <TouchableOpacity
                onPress={() => showCopyrightInfo()}
              >
                <Image
                  style={{ height: 20, width: 20, marginVertical: 2 }}
                  resizeMode="contain"
                  source={require("../assets/copyright-regular.png")}
                />
              </TouchableOpacity>
            )} */}

            <TouchableOpacity onPress={toggleFaves}>
              {favourited ? (
                <Image
                  style={{ height: 20, width: 20, marginVertical: 2 }}
                  resizeMode="contain"
                  source={require("../assets/heart-white.png")}
                />
              ) : (
                <Image
                  style={{ height: 20, width: 20, marginVertical: 2 }}
                  resizeMode="contain"
                  source={require("../assets/heart-outline-white.png")}
                />
              )}
            </TouchableOpacity>
          </View>

          {
            // showCopyright ? (
            imageOwner.length === 1 ?
              <View style={styles.copyrightMessage}>
                <Text style={{ color: "white", fontSize: 10 }}>Photo by </Text>
                <TouchableOpacity onPress={openImageOwner}>
                  <Text
                    style={{
                      color: "white",
                      fontSize: 10,
                      textDecorationLine: "underline",
                    }}
                  >
                    {imageOwner[0].substring(21)}
                  </Text>
                </TouchableOpacity>
              </View>
              :
              <View style={styles.copyrightMessage}>
                <Text style={{ color: "white", fontSize: 10 }}>{imageOwner[1]} (</Text>
                <TouchableOpacity onPress={openSubmitDogForm}>
                  <Text
                    style={{
                      color: "white",
                      fontSize: 10,
                      textDecorationLine: "underline",
                    }}
                  >
                    shared
                  </Text>
                </TouchableOpacity>
                <Text style={{ color: "white", fontSize: 10 }}> by {imageOwner[0]})</Text>
              </View>
            // ) : null
          }
        </View>
      )}

      <View style={{ marginVertical: 6, alignItems: "center" }}>
        <View style={styles.inputArea}>
          <View style={styles.guessBox}>
            <AutoCompleteSB_1
              breed={breed1}
              handleSelect={handleSelect1}
              guessedCorrectly={breed1Correct}
              singlebreed={true}
              gameEnded={gameWon}
            />
          </View>
        </View>

        {lastGuessRound >= 1 ? (
          <View style={styles.inputArea}>
            <View style={styles.guessBox}>
              {breed1Correct ? (
                <AutoCompleteSB_Empty />
              ) : (
                <AutoCompleteSB_2
                  breed={breed2}
                  handleSelect={handleSelect2}
                  guessedCorrectly={breed2Correct}
                  singlebreed={true}
                  gameEnded={gameWon}
                />
              )}
            </View>
          </View>
        ) : (
          <View style={styles.inputArea}>
            <View style={styles.guessBox}>
              <AutoCompleteSB_Empty />
            </View>
          </View>
        )}

        {lastGuessRound >= 2 ? (
          <View style={styles.inputArea}>
            <View style={styles.guessBox}>
              {breed1Correct || breed2Correct ? (
                <AutoCompleteSB_Empty />
              ) : (
                <AutoCompleteSB_3
                  breed={breed3}
                  handleSelect={handleSelect3}
                  guessedCorrectly={breed3Correct}
                  singlebreed={true}
                  gameEnded={gameWon}
                />
              )}
            </View>
          </View>
        ) : (
          <View style={styles.inputArea}>
            <View style={styles.guessBox}>
              <AutoCompleteSB_Empty />
            </View>
          </View>
        )}

        {lastGuessRound >= 3 ? (
          <View style={styles.inputArea}>
            <View style={styles.guessBox}>
              {breed1Correct || breed2Correct || breed3Correct ? (
                <AutoCompleteSB_Empty />
              ) : (
                <AutoCompleteSB_4
                  breed={breed4}
                  handleSelect={handleSelect4}
                  guessedCorrectly={breed4Correct}
                  singlebreed={true}
                  gameEnded={gameWon}
                />
              )}
            </View>
          </View>
        ) : (
          <View style={styles.inputArea}>
            <View style={styles.guessBox}>
              <AutoCompleteSB_Empty />
            </View>
          </View>
        )}

        {lastGuessRound >= 4 ? (
          <View style={styles.inputArea}>
            <View style={styles.guessBox}>
              {breed1Correct || breed2Correct || breed3Correct || breed4Correct ? (
                <AutoCompleteSB_Empty />
              ) : (
                <AutoCompleteSB_5
                  breed={breed5}
                  handleSelect={handleSelect5}
                  guessedCorrectly={breed5Correct}
                  singlebreed={true}
                  gameEnded={gameWon}
                />
              )}
            </View>
          </View>
        ) : (
          <View style={styles.inputArea}>
            <View style={styles.guessBox}>
              <AutoCompleteSB_Empty />
            </View>
          </View>
        )}

        {gameWon === null ? (
          <>
            <View>
              <Pressable onPress={checkGuessFull} style={styles.button}>
                <Text style={{ fontSize: 16, fontWeight: 600, color: "white" }}>
                  Submit
                </Text>
              </Pressable>
            </View>

            <View>
              <Pressable onPress={() => setRevealAnswerModalVisible(true)} style={styles.secondaryButton}>
                <Text style={{ color: "#2C41FF" }}>
                  Reveal answer
                </Text>
              </Pressable>
            </View>

            <Modal
              transparent={true}
              visible={revealAnswerModalVisible}
              onRequestClose={() => {
                setRevealAnswerModalVisible(!revealAnswerModalVisible)
              }}>
              <View style={styles.centeredView}>
                <View style={styles.modalView}>
                  <View style={styles.modalHeader}>
                    <Pressable onPress={() => setRevealAnswerModalVisible(false)}>
                      <Image
                        style={{ height: 16, width: 16, marginRight: 15, marginTop: 15 }}
                        source={require('../assets/close.png')}
                      />
                    </Pressable>
                  </View>

                  <View style={{ paddingHorizontal: 20, paddingBottom: 20 }}>
                    <Text style={{ fontSize: 16, textAlign: 'center', marginBottom: 10 }}>Are you sure you want to see the answer? This will automatically finish today's game and update your stats.</Text>
                    <View>
                      <Pressable onPress={() => setRevealAnswerModalVisible(false)} style={styles.button}>
                        <Text style={{ color: 'white', fontSize: 16 }}>No, I'll keep playing</Text>
                      </Pressable>
                      <Pressable onPress={revealAnswer} style={{ ...styles.button, backgroundColor: 'white', borderColor: '#2C41FF', borderWidth: 1 }}>
                        <Text style={{ fontSize: 16 }}>Yes, reveal the answer</Text>
                      </Pressable>
                    </View>
                  </View>

                </View>
              </View>
            </Modal>
          </>

        ) :
          <>
            <PostGameMessage singleBreed={true} breed={breed} owner={imageOwner} socialMedia={imageOwner?.length > 2 ? imageOwner[3] : null} setModalVisible={setModalVisible} />

            <Modal
              transparent={true}
              visible={modalVisible}
              onRequestClose={() => {
                setModalVisible(!modalVisible)
              }}>
              <View style={styles.centeredView}>
                <View style={{ ...styles.modalView, backgroundColor: gameWon === true ? '#CBF2CA' : '#EFBDBD' }}>
                  <View style={styles.modalHeader}>

                    <Pressable onPress={closeModal}>
                      <Image
                        style={{ height: 16, width: 16, marginRight: 15, marginTop: 15 }}
                        source={require('../assets/close.png')}
                      />
                    </Pressable>
                  </View>

                  <PostGameModal gameWon={gameWon} singleBreed={true} breed={breed} owner={imageOwner} imageOwner={imageOwner} imageURL={imageURL} imageResizeMode={imageResizeMode} socialMedia={imageOwner?.length > 2 ? imageOwner[3] : null} />

                </View>
              </View>
            </Modal>
          </>
        }
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    paddingTop: 10,
  },
  imageBox: {
    height: deviceSize === 'large' ? 340 : deviceSize === 'medium' ? 300 : 280,
    width: deviceSize === 'large' ? 390 : 350,
    backgroundColor: '#E7E7E7',
    borderRadius: 5
  },
  inputArea: {
    width: deviceSize === 'large' ? 390 : 350,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  guessBox: {
    width: deviceSize === 'large' ? 390 : 350
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    // backgroundColor: "#456990",
    backgroundColor: "#2C41FF",
    borderRadius: 20,
    borderColor: "gray",
    height: 35,
    padding: 20,
    marginTop: 5,
  },
  secondaryButton: {
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    borderWidth: 1,
    borderColor: "#2C41FF",
    height: 25,
    padding: 10,
    marginTop: 30
  },
  lives: {
    flexDirection: "row",
    marginTop: 20,
  },
  imageButtons: {
    marginRight: 5,
    marginBottom: 5,
    position: "absolute",
    bottom: 0,
    right: 0,
    alignItems: "center",
    justifyContent: "space-around",
    backgroundColor: "rgba(0,0,0,0.4)",
    padding: 5,
    borderRadius: 7,
  },
  copyrightMessage: {
    flexDirection: "row",
    marginLeft: 5,
    marginBottom: 5,
    position: "absolute",
    bottom: 0,
    left: 0,
    alignItems: "center",
    justifyContent: "space-around",
    backgroundColor: "rgba(0,0,0,0.4)",
    padding: 5,
    borderRadius: 7,
  },
  // Modal styles
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: 'rgba(0,0,0,0.5)'
    backgroundColor: 'rgba(0,0,0,0.75)'
  },
  modalView: {
    width: '85%',
    maxWidth: 600,
    backgroundColor: 'white',
    // backgroundColor: '#0011A7',
    // backgroundColor: '#B4EFB2',
    // borderRadius: 20,
    borderRadius: 10,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    overflow: 'hidden'
  },
  modalHeader: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    // backgroundColor: 'lightblue',
    alignItems: 'center',
    // padding: 15,
    // borderBottomWidth: 0.5,
    // borderBottomColor: 'lightgray',
  }
})
