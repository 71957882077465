import { useEffect, useState } from 'react'
import { ActivityIndicator, Text, View, StyleSheet, Image, TouchableOpacity } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import * as WebBrowser from 'expo-web-browser'

import app from '../firebaseConfig'
import { getAnalytics, logEvent } from "firebase/analytics"
const analytics = getAnalytics(app)

export default function FavouritesScreen(props) {
    const [isLoading, setLoading] = useState(true)
    const [faves, setFaves] = useState(null)

    useEffect(() => {
        getFavouritesFromLS()
        setLoading(false)
        logEvent(analytics, 'SCREEN_VIEW_favourites')
    }, [])

    const getFavouritesFromLS = async () => {
        try {
            const favesData = await AsyncStorage.getItem('faves')
            const favesDataParsed = JSON.parse(favesData)
            setFaves(favesDataParsed)
        } catch (error) {
            console.log(error)
        }
    }

    const removeFromFaves = async (url, idx) => {
        try {
            const result = await AsyncStorage.getItem("faves")
            const id = url
            if (result !== null) {
                let newIds = JSON.parse(result).filter((item) => item !== id)
                AsyncStorage.setItem("faves", JSON.stringify(newIds))
                getFavouritesFromLS()
            } else {
                getFavouritesFromLS()
            }
            faves.length - 1 === idx && console.log('About to call passUnfaveBack')
            faves.length - 1 === idx && props.passUnfaveBack()
            logEvent(analytics, 'delete_favourited_image')
        } catch (e) {
            console.log(e)
            logEvent(analytics, 'ERROR_delete_favourited_image')
        }
    }

    const openImage = async (url) => {
        await WebBrowser.openBrowserAsync(url)
        logEvent(analytics, 'open_favourited_image')
    }

    return (
        <View style={styles.container}>
            <Text style={{ marginBottom: 15, fontWeight: 600, fontSize: 24, alignSelf: 'center' }}>Favourites</Text>
            {isLoading === true
                ?
                <ActivityIndicator size="large" />
                :
                faves !== null && faves.length > 0
                    ?
                    <>
                        <View style={{ flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center', marginBottom: 5 }}>
                            {
                                faves.map((url, idx) => {
                                    return <View key={idx} style={{ flexDirection: 'column' }}>
                                        <TouchableOpacity onPress={() => removeFromFaves(url, idx)} style={{ alignSelf: 'flex-end', justifyContent: 'center', zIndex: 100, marginBottom: -16, width: 18, height: 18, backgroundColor: 'orange', borderRadius: 5 }}>
                                            <Image
                                                style={{ height: 8, width: 8, alignSelf: 'center' }}
                                                source={require('../assets/close-light.png')}
                                            />
                                        </TouchableOpacity>
                                        <TouchableOpacity onPress={() => openImage(url)}>
                                            <Image
                                                style={{ borderWidth: 1, borderColor: 'gray', height: 80, width: 80, borderRadius: 5, margin: 3 }}
                                                resizeMode='cover'
                                                source={{
                                                    uri: url,
                                                }}
                                            />
                                        </TouchableOpacity>
                                    </View>

                                })
                            }
                        </View>
                        <Text style={{ marginBottom: 5, textAlign: 'center', fontSize: 10, fontStyle: 'italic' }}>Tap thumbnail to load image in new tab.{"\n"}Clearing browser data will remove all favourited images.</Text>
                    </>
                    :
                    <Text style={{
                        marginBottom: 5,
                        marginBottom: 5,
                        textAlign: 'center',
                    }}>Get some DOGGLE inspiration - save your faves and they'll show here!</Text>
            }

        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        width: '95%',
        marginBottom: 15
    },
})