import { useState, useEffect } from "react"
import { Button, Dimensions, Image, Pressable, SafeAreaView } from "react-native"
import { getFirestore, collection, doc, addDoc, getDoc, setDoc, serverTimestamp } from "firebase/firestore"
import { getAnalytics, logEvent } from "firebase/analytics"
import AsyncStorage from "@react-native-async-storage/async-storage"
import SegmentedControl from "@react-native-segmented-control/segmented-control"
import * as WebBrowser from 'expo-web-browser'
import FlashMessage from "react-native-flash-message"

import Navbar from "./components/Navbar"
import SingleBreed from "./components/SingleBreed"
import MixedBreed from "./components/MixedBreed"
// import data from './data/dataForFirebase_updated_12Feb'

import app from './firebaseConfig'
const db = getFirestore(app)
const analytics = getAnalytics(app)

const screenWidth = Dimensions.get('screen').width
const deviceSize = screenWidth > 415 ? 'large' : screenWidth > 380 ? 'medium' : 'small'

export default function App() {
  const [isLoading, setIsLoading] = useState(true)
  const [firstPlay, setFirstPlay] = useState(false)
  const [singleBreed, setSingleBreed] = useState(null)
  const [mixedBreed, setMixedBreed] = useState(null)
  const [gameModes, setGameModes] = useState([])
  const [gameModeIndex, setGameModeIndex] = useState(0)

  const [recentlyUnfaved, setRecentlyUnfaved] = useState(false)

  const getAndSetGameModesFromLS = async () => {
    let gameModesLS = JSON.parse(await AsyncStorage.getItem("gameModes"))
    if (gameModesLS === null || gameModesLS.length === 0) {
      await AsyncStorage.setItem("gameModes", JSON.stringify(["mixedBreed", "singleBreed"]))
      setGameModes(["mixedBreed", "singleBreed"])
    } else {
      setGameModes(gameModesLS)
    }
  }

  const getAndSetDogFromFirebase = async () => {
    let mbLastPlayTs = JSON.parse(await AsyncStorage.getItem("mbLastTimestamp"))
    let sbLastPlayTs = JSON.parse(await AsyncStorage.getItem("sbLastTimestamp"))
    if (mbLastPlayTs === null && sbLastPlayTs == null) {
      setFirstPlay(true)
    }

    const todayDate = new Date().toLocaleDateString('en-GB')
    const docDate = todayDate.replaceAll('/', '-')

    const newDateSplit = docDate.split('-')
    const newDateReversed = newDateSplit.reverse()
    const newDocDate = newDateReversed.join('-')

    const docSnap = await getDoc(doc(db, "dailyDogs", newDocDate))
    if (docSnap.exists()) {
      setSingleBreed(docSnap.data().singleBreed)
      setMixedBreed(docSnap.data().mixedBreed)
      await AsyncStorage.setItem("fact", JSON.stringify(docSnap.data().fact))
      await AsyncStorage.setItem("dogNumber", JSON.stringify(docSnap.data().dogNumber))
      setIsLoading(false)
    } else {
      logEvent(analytics, 'ERROR_loading_from_fb')
      setIsLoading(false)
      // set backup Dog data in case of edgecase fails?
    }
  }

  const saveFeedback = async (userInput) => {
    try {
      await addDoc(collection(db, "userFeedback"), {
        comment: userInput,
        timestamp: serverTimestamp(),
      })
      logEvent(analytics, 'submit_feedback')
    } catch (e) {
      logEvent(analytics, 'ERROR_submit_feedback')
    }
  }

  useEffect(() => {
    getAndSetDogFromFirebase()
    getAndSetGameModesFromLS()
  }, [])

  // const addToFirebase = async () => {
  //   try {
  //     data.forEach(async (item, idx) => {
  //       await setDoc(doc(db, "dailyDogs", item.date), item)
  //     })
  //   } catch (e) {
  //     console.log(e)
  //   }
  // }

  const passGameModesBack = (updatedGameModes) => {
    setGameModes(updatedGameModes)
  }

  const passUnfaveBack = () => {
    setRecentlyUnfaved(true)
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <Navbar passGameModesBack={passGameModesBack} saveFeedback={saveFeedback} passUnfaveBack={passUnfaveBack} firstPlay={firstPlay} />
      {/* <Button title="Add to firebase" onPress={addToFirebase} /> */}
      {
        gameModes !== null && gameModes.length === 2 ?
          <>
            <SegmentedControl
              style={{ width: deviceSize === 'large' ? 390 : 350, alignSelf: "center", marginTop: 10 }}
              fontStyle={{ color: 'grey' }}
              activeFontStyle={{ color: 'white' }}
              tintColor="#2C41FF"
              values={["Single Breed", "Mixed Breed"]}
              selectedIndex={gameModeIndex}
              onChange={(event) => {
                setGameModeIndex(event.nativeEvent.selectedSegmentIndex)
              }}
            />
            {
              isLoading !== true && gameModeIndex === 0
                ? singleBreed !== null && singleBreed !== undefined ? <SingleBreed data={singleBreed} recentlyUnfaved={recentlyUnfaved} setRecentlyUnfaved={setRecentlyUnfaved} /> : null
                : mixedBreed !== null && mixedBreed !== undefined ? <MixedBreed data={mixedBreed} recentlyUnfaved={recentlyUnfaved} setRecentlyUnfaved={setRecentlyUnfaved} /> : null
            }
          </>
          : gameModes.includes("singleBreed") && singleBreed !== null && singleBreed !== undefined
            ? <SingleBreed data={singleBreed} recentlyUnfaved={recentlyUnfaved} setRecentlyUnfaved={setRecentlyUnfaved} />
            : mixedBreed !== null && mixedBreed !== undefined
              ? <MixedBreed data={mixedBreed} recentlyUnfaved={recentlyUnfaved} setRecentlyUnfaved={setRecentlyUnfaved} /> : null
      }

      {/* <Pressable style={{ marginVertical: 10, alignSelf: 'center' }} onPress={async () => await WebBrowser.openBrowserAsync('https://ko-fi.com/N4N6FCSUC')}>
        <Image
          style={{ height: 30, width: 180 }}
          resizeMode='contain'
          source={require('./assets/kofi_button_red1.png')} />
      </Pressable> */}

      <FlashMessage position="bottom" />
    </SafeAreaView>
  )
}