import { useState, useEffect } from 'react'
import { Image, Modal, Pressable, StyleSheet, Text, View } from 'react-native'
import StatsScreen from '../screens/StatsScreen'
import SettingsScreen from '../screens/SettingsScreen'
import InfoScreen from '../screens/InfoScreen'
import FavouritesScreen from '../screens/FavouritesScreen'

export default function Navbar(props) {
    const { passGameModesBack, saveFeedback, firstPlay, passUnfaveBack } = props

    const [modalVisible, setModalVisible] = useState(false)
    const [showSettings, setShowSettings] = useState(false)
    const [showStats, setShowStats] = useState(false)
    const [showInfo, setShowInfo] = useState(false)
    const [showFaves, setShowFaves] = useState(false)

    const [date, setDate] = useState('')

    useEffect(() => {
        firstPlay === true ? showInfoModal() : null

        const todayDate = new Date().toLocaleDateString('en-GB')
        setDate(todayDate.replaceAll('/', '-'))

    }, [firstPlay])

    // const prepareToPassUnfaveBack = () => {
    //     passUnfaveBack()
    // }

    const showSettingsModal = () => {
        setShowSettings(true)
        setModalVisible(true)
    }

    const showStatsModal = () => {
        setShowStats(true)
        setModalVisible(true)
    }

    const showInfoModal = () => {
        setShowInfo(true)
        setModalVisible(true)
    }

    const showFavesModal = () => {
        setShowFaves(true)
        setModalVisible(true)
    }

    const closeModal = () => {
        setModalVisible(false)
        setShowSettings(false)
        setShowStats(false)
        setShowInfo(false)
        setShowFaves(false)
    }

    return (
        <>
            <View style={styles.navbar}>
                <View style={styles.badgeGroup}>
                    <Pressable onPress={showInfoModal}>
                        {
                            !showInfo
                                ?
                                <Image
                                    style={{ height: 22, width: 22 }}
                                    resizeMode='contain'
                                    source={require('../assets/help-circle-outline.png')} />
                                :
                                <Image
                                    style={{ height: 22, width: 22 }}
                                    resizeMode='contain'
                                    source={require('../assets/help-circle.png')} />
                        }
                    </Pressable>
                    <Pressable onPress={showFavesModal}>
                        {
                            !showFaves
                                ?
                                <Image
                                    style={{ height: 22, width: 22, marginLeft: 12 }}
                                    resizeMode='contain'
                                    source={require('../assets/heart-outline.png')} />
                                :
                                <Image
                                    style={{ height: 22, width: 22, marginLeft: 12 }}
                                    resizeMode='contain'
                                    source={require('../assets/heart.png')} />

                        }
                    </Pressable>
                </View>
                {
                    date === '24-12-2022' || date === '25-12-2022' ?
                        <Image
                            style={{ height: 40, width: 140 }}
                            resizeMode='contain'
                            source={require('../assets/DoggleLogoXmas.png')} />
                        :
                        <Image
                            style={{ height: 40, width: 140 }}
                            resizeMode='contain'
                            source={require('../assets/DoggleLogo2.png')} />

                }
                {/* <Image
                    style={{ height: 40, width: 140 }}
                    resizeMode='contain'
                    source={require('../assets/DoggleLogoXmas.png')} /> */}
                <View style={styles.badgeGroup}>
                    <Pressable onPress={showStatsModal}>
                        {
                            !showStats
                                ?
                                <Image
                                    style={{ height: 22, width: 22, marginRight: 12 }}
                                    resizeMode='contain'
                                    source={require('../assets/stats.png')} />
                                :
                                <Image
                                    style={{ height: 22, width: 22, marginRight: 12 }}
                                    resizeMode='contain'
                                    source={require('../assets/stats-filled.png')} />

                        }
                    </Pressable>
                    <Pressable onPress={showSettingsModal}>
                        {
                            !showSettings
                                ?
                                <Image
                                    style={{ height: 22, width: 22 }}
                                    resizeMode='contain'
                                    source={require('../assets/settings-outline.png')} />
                                :
                                <Image
                                    style={{ height: 22, width: 22 }}
                                    resizeMode='contain'
                                    source={require('../assets/settings.png')} />

                        }
                    </Pressable>
                </View>
            </View>
            <Modal
                transparent={true}
                visible={modalVisible}
                onRequestClose={() => {
                    setModalVisible(!modalVisible)
                }}>
                <View style={styles.centeredView}>
                    <View style={styles.modalView}>

                        <View style={styles.modalHeader}>
                            <Pressable onPress={closeModal}>
                                <Image
                                    style={{ height: 16, width: 16, marginRight: 15, marginTop: 15 }}
                                    source={require('../assets/close.png')}
                                />
                            </Pressable>
                        </View>
                        {showSettings ? <SettingsScreen passGameModesBack={passGameModesBack} saveFeedback={saveFeedback} />
                            : showStats ? <StatsScreen />
                                : showInfo ? <InfoScreen closeModal={closeModal} />
                                    : showFaves ? <FavouritesScreen passUnfaveBack={passUnfaveBack} />
                                        : null}
                    </View>
                </View>
            </Modal>
        </>
    )
}
const styles = StyleSheet.create({
    navbar: {
        paddingVertical: 5,
        paddingHorizontal: 15,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottomColor: 'lightgray',
        borderBottomWidth: 1,
    },
    badgeGroup: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.8)'
    },
    modalView: {
        width: '85%',
        backgroundColor: 'white',
        borderRadius: 10,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        overflow: 'hidden'
    },
    modalHeader: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    }
})