import { useEffect, useState } from "react"
import { ActivityIndicator, Dimensions, Modal, Image, Pressable, StyleSheet, Text, TouchableOpacity, View } from "react-native"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { showMessage } from "react-native-flash-message"
import * as WebBrowser from 'expo-web-browser'

import PostGameModal from "../screens/PostGameModal"

import app from '../firebaseConfig'
import { getAnalytics, logEvent } from "firebase/analytics"
const analytics = getAnalytics(app)

import AutoCompleteMB_A1 from "./AutoComplete"
import AutoCompleteMB_A2 from "./AutoComplete"
import AutoCompleteMB_A3 from "./AutoComplete"
import AutoCompleteMB_A4 from "./AutoComplete"
import AutoCompleteMB_A5 from "./AutoComplete"
import AutoCompleteMB_B1 from "./AutoComplete"
import AutoCompleteMB_B2 from "./AutoComplete"
import AutoCompleteMB_B3 from "./AutoComplete"
import AutoCompleteMB_B4 from "./AutoComplete"
import AutoCompleteMB_B5 from "./AutoComplete"
import AutoCompleteMB_Empty from "./AutoCompleteEmpty"
import PostGameMessage from "./PostGameMessage"

const screenWidth = Dimensions.get('screen').width;
const deviceSize = screenWidth > 415 ? 'large' : screenWidth > 380 ? 'medium' : 'small'

export default function MixedBreed(props) {
  const { breeds, imageURL, imageOwner, nickname, imageResizeMode } = props.data
  const [isLoading, setLoading] = useState(true)

  const [lastGuessRound, setLastGuessRound] = useState(0)
  const [gameWon, setGameWon] = useState(null)
  const [favourited, setFavourited] = useState(false)
  const [showCopyright, setShowCopyright] = useState(false)

  const [modalVisible, setModalVisible] = useState(false)
  const [revealAnswerModalVisible, setRevealAnswerModalVisible] = useState(false)
  const closeModal = () => setModalVisible(false)

  const [statsGamesPlayed, setStatsGamesPlayed] = useState(null)
  const [statsGuessDistribution, setStatsGuessDistribution] = useState([])
  const [statsBreedsCorrectlyGuessed, setStatsBreedsCorrectlyGuessed] = useState(null)

  const [breedA1, setBreedA1] = useState(null)
  const [breedA2, setBreedA2] = useState(null)
  const [breedA3, setBreedA3] = useState(null)
  const [breedA4, setBreedA4] = useState(null)
  const [breedA5, setBreedA5] = useState(null)
  const [breedB1, setBreedB1] = useState(null)
  const [breedB2, setBreedB2] = useState(null)
  const [breedB3, setBreedB3] = useState(null)
  const [breedB4, setBreedB4] = useState(null)
  const [breedB5, setBreedB5] = useState(null)

  const [breedA1Correct, setBreedA1Correct] = useState(null)
  const [breedA2Correct, setBreedA2Correct] = useState(null)
  const [breedA3Correct, setBreedA3Correct] = useState(null)
  const [breedA4Correct, setBreedA4Correct] = useState(null)
  const [breedA5Correct, setBreedA5Correct] = useState(null)
  const [breedB1Correct, setBreedB1Correct] = useState(null)
  const [breedB2Correct, setBreedB2Correct] = useState(null)
  const [breedB3Correct, setBreedB3Correct] = useState(null)
  const [breedB4Correct, setBreedB4Correct] = useState(null)
  const [breedB5Correct, setBreedB5Correct] = useState(null)

  const handleSelectA1 = (breed) => { setBreedA1(breed) }
  const handleSelectA2 = (breed) => { setBreedA2(breed) }
  const handleSelectA3 = (breed) => { setBreedA3(breed) }
  const handleSelectA4 = (breed) => { setBreedA4(breed) }
  const handleSelectA5 = (breed) => { setBreedA5(breed) }
  const handleSelectB1 = (breed) => { setBreedB1(breed) }
  const handleSelectB2 = (breed) => { setBreedB2(breed) }
  const handleSelectB3 = (breed) => { setBreedB3(breed) }
  const handleSelectB4 = (breed) => { setBreedB4(breed) }
  const handleSelectB5 = (breed) => { setBreedB5(breed) }

  useEffect(() => {
    getDataFromLS()
    setLoading(false)
    logEvent(analytics, 'SCREEN_VIEW_mixed_breed')
  }, [])

  useEffect(() => {
    lastGuessRound === 1
      ? saveFirstRoundGuessesToLS(breedA1, breedB1)
      : lastGuessRound === 2
        ? saveSecondRoundGuessesToLS(breedA2, breedB2)
        : lastGuessRound === 3
          ? saveThirdRoundGuessesToLS(breedA3, breedB3)
          : lastGuessRound === 4
            ? saveFourthRoundGuessesToLS(breedA4, breedB4)
            : lastGuessRound === 5
              ? saveFifthRoundGuessesToLS(breedA5, breedB5)
              : null

    lastGuessRound > 4 && gameWon !== true ? setGameWon(false) : null
  }, [lastGuessRound])

  useEffect(() => {
    if (gameWon === true) {
      saveMBWinToLS(true)
      saveStatsToLS()
      setTimeout(() => setModalVisible(true), 1500)
    } else if (gameWon === false) {
      saveMBWinToLS(false)
      saveStatsToLS()
      setTimeout(() => setModalVisible(true), 1500)
    }
  }, [gameWon])

  useEffect(() => {
    if (props.recentlyUnfaved === true) {
      setFavourited(false)
      props.setRecentlyUnfaved(false)
    }
  }, [props.recentlyUnfaved])

  const openImageOwner = async () => {
    await WebBrowser.openBrowserAsync(imageOwner)
    logEvent(analytics, 'open_image_owner')
  }

  const saveStatsToLS = async () => {
    const statsTimestamp = await AsyncStorage.getItem("mbStatsLastUpdated")

    const today = new Date().setHours(0, 0, 0, 0)
    const statsTsAsDay = new Date(Number(statsTimestamp)).setHours(0, 0, 0, 0)

    if (statsTimestamp === null || statsTsAsDay !== today) {
      const mbBreedsCorrect = {
        mbBreedA1Correct: breedA1Correct,
        mbBreedB1Correct: breedB1Correct,
        mbBreedA2Correct: breedA2Correct,
        mbBreedB2Correct: breedB2Correct,
        mbBreedA3Correct: breedA3Correct,
        mbBreedB3Correct: breedB3Correct,
        mbBreedA4Correct: breedA4Correct,
        mbBreedB4Correct: breedB4Correct,
        mbBreedA5Correct: breedA5Correct,
        mbBreedB5Correct: breedB5Correct,
      }

      const numOfTrues = Object.values(mbBreedsCorrect).reduce(
        (a, item) => a + item,
        0
      )

      if (numOfTrues === 0) {
        await AsyncStorage.setItem(
          "mbStatsGuessDistribution",
          JSON.stringify([
            statsGuessDistribution[0] + 1,
            statsGuessDistribution[1],
            statsGuessDistribution[2],
          ])
        )
      } else if (numOfTrues === 1) {
        await AsyncStorage.setItem(
          "mbStatsGuessDistribution",
          JSON.stringify([
            statsGuessDistribution[0],
            statsGuessDistribution[1] + 1,
            statsGuessDistribution[2],
          ])
        )
      } else {
        await AsyncStorage.setItem(
          "mbStatsGuessDistribution",
          JSON.stringify([
            statsGuessDistribution[0],
            statsGuessDistribution[1],
            statsGuessDistribution[2] + 1,
          ])
        )
      }

      await AsyncStorage.setItem(
        "mbStatsBreedsCorrectlyGuessed",
        JSON.stringify(statsBreedsCorrectlyGuessed + numOfTrues)
      )
      await AsyncStorage.setItem(
        "mbStatsGamesPlayed",
        JSON.stringify(statsGamesPlayed + 1)
      )
      await AsyncStorage.setItem(
        "mbStatsLastUpdated",
        JSON.stringify(Date.now())
      )
      logEvent(analytics, 'MB_game_finished')

      numOfTrues === 2 ? logEvent(analytics, 'MB_breeds_correct_2')
        : numOfTrues === 1 ? logEvent(analytics, 'MB_breeds_correct_1')
          : logEvent(analytics, 'MB_breeds_correct_0')

      numOfTrues === 2 ? logEvent(analytics, 'MB_game_won') : logEvent(analytics, 'MB_game_lost')
    }
  }

  const clearLS = async () => {
    try {
      await AsyncStorage.removeItem("mbGameWon")
      await AsyncStorage.removeItem("mbLastGuessRound")
      await AsyncStorage.removeItem("mbLastTimestamp")
      await AsyncStorage.removeItem("mbBreeds")
      await AsyncStorage.removeItem("mbBreedsCorrect")
      await AsyncStorage.removeItem("mbStatsLastUpdated")
    } catch (error) {
      console.log(error)
    }
  }

  const getDataFromLS = async () => {
    const gameWonLS = JSON.parse(await AsyncStorage.getItem("mbGameWon"))
    const lastGuessRoundLS = JSON.parse(
      await AsyncStorage.getItem("mbLastGuessRound")
    )
    const lastTsLS = JSON.parse(await AsyncStorage.getItem("mbLastTimestamp"))
    const faveImagesLS = JSON.parse(await AsyncStorage.getItem("faves"))

    //get Stats
    const statsGamesPlayedLS = JSON.parse(await AsyncStorage.getItem("mbStatsGamesPlayed"))
    const statsBreedsCorrectlyGuessedLS = JSON.parse(await AsyncStorage.getItem("mbStatsBreedsCorrectlyGuessed"))
    const statsGuessDistributionLS = JSON.parse(await AsyncStorage.getItem("mbStatsGuessDistribution"))

    statsGamesPlayedLS === null ? setStatsGamesPlayed(0) : setStatsGamesPlayed(statsGamesPlayedLS)
    statsBreedsCorrectlyGuessedLS === null ? setStatsBreedsCorrectlyGuessed(0) : setStatsBreedsCorrectlyGuessed(statsBreedsCorrectlyGuessedLS)
    statsGuessDistributionLS === null ? setStatsGuessDistribution([0, 0, 0]) : setStatsGuessDistribution(statsGuessDistributionLS)

    if (lastTsLS === null) {
      setGameWon(null)
      faveImagesLS !== null &&
        faveImagesLS.includes(imageURL) &&
        setFavourited(true)
      clearLS()
      return
    }

    try {
      const today = new Date().setHours(0, 0, 0, 0)
      const lastPlayedDay = new Date(Number(lastTsLS)).setHours(0, 0, 0, 0)

      // If lastPlayedDay is not today, then clear localStorage & reset game/scores (as user is playing for first time today)
      // If it is today, then keep current scores
      if (lastPlayedDay !== today) {
        setGameWon(null)
        faveImagesLS !== null &&
          faveImagesLS.includes(imageURL) &&
          setFavourited(true)
        clearLS()
      } else {
        const breeds = JSON.parse(await AsyncStorage.getItem("mbBreeds"))
        const breedsCorrect = JSON.parse(
          await AsyncStorage.getItem("mbBreedsCorrect")
        )

        setBreedA1(breeds.mbBreedA1 === undefined ? null : breeds.mbBreedA1)
        setBreedB1(breeds.mbBreedB1 === undefined ? null : breeds.mbBreedB1)
        setBreedA2(breeds.mbBreedA2 === undefined ? null : breeds.mbBreedA2)
        setBreedB2(breeds.mbBreedB2 === undefined ? null : breeds.mbBreedB2)
        setBreedA3(breeds.mbBreedA3 === undefined ? null : breeds.mbBreedA3)
        setBreedB3(breeds.mbBreedB3 === undefined ? null : breeds.mbBreedB3)
        setBreedA4(breeds.mbBreedA4 === undefined ? null : breeds.mbBreedA4)
        setBreedB4(breeds.mbBreedB4 === undefined ? null : breeds.mbBreedB4)
        setBreedA5(breeds.mbBreedA5 === undefined ? null : breeds.mbBreedA5)
        setBreedB5(breeds.mbBreedB5 === undefined ? null : breeds.mbBreedB5)
        setBreedA1Correct(breedsCorrect.mbBreedA1Correct === undefined ? null : breedsCorrect.mbBreedA1Correct)
        setBreedB1Correct(breedsCorrect.mbBreedB1Correct === undefined ? null : breedsCorrect.mbBreedB1Correct)
        setBreedA2Correct(breedsCorrect.mbBreedA2Correct === undefined ? null : breedsCorrect.mbBreedA2Correct)
        setBreedB2Correct(breedsCorrect.mbBreedB2Correct === undefined ? null : breedsCorrect.mbBreedB2Correct)
        setBreedA3Correct(breedsCorrect.mbBreedA3Correct === undefined ? null : breedsCorrect.mbBreedA3Correct)
        setBreedB3Correct(breedsCorrect.mbBreedB3Correct === undefined ? null : breedsCorrect.mbBreedB3Correct)
        setBreedA4Correct(breedsCorrect.mbBreedA4Correct === undefined ? null : breedsCorrect.mbBreedA4Correct)
        setBreedB4Correct(breedsCorrect.mbBreedB4Correct === undefined ? null : breedsCorrect.mbBreedB4Correct)
        setBreedA5Correct(breedsCorrect.mbBreedA5Correct === undefined ? null : breedsCorrect.mbBreedA5Correct)
        setBreedB5Correct(breedsCorrect.mbBreedB5Correct === undefined ? null : breedsCorrect.mbBreedB5Correct)

        setGameWon(gameWonLS)
        faveImagesLS !== null && faveImagesLS.includes(imageURL) && setFavourited(true)
        !lastGuessRoundLS ? setLastGuessRound(0) : setLastGuessRound(Number(lastGuessRoundLS))
      }
    } catch (error) {
      console.log(error)
    }
  }

  const saveFirstRoundGuessesToLS = async () => {
    try {
      const mbBreeds = {
        mbBreedA1: breedA1,
        mbBreedB1: breedB1,
      }
      const mbBreedsCorrect = {
        mbBreedA1Correct: breedA1Correct,
        mbBreedB1Correct: breedB1Correct,
      }
      await AsyncStorage.setItem("mbBreeds", JSON.stringify(mbBreeds))
      await AsyncStorage.setItem(
        "mbBreedsCorrect",
        JSON.stringify(mbBreedsCorrect)
      )
      await AsyncStorage.setItem("mbLastTimestamp", Date.now())
      await AsyncStorage.setItem("mbLastGuessRound", 1)
    } catch (e) {
      console.log(e)
    }
  }

  const saveSecondRoundGuessesToLS = async () => {
    try {
      const mbBreeds = {
        mbBreedA1: breedA1,
        mbBreedB1: breedB1,
        mbBreedA2: breedA2,
        mbBreedB2: breedB2,
      }
      const mbBreedsCorrect = {
        mbBreedA1Correct: breedA1Correct,
        mbBreedB1Correct: breedB1Correct,
        mbBreedA2Correct: breedA2Correct,
        mbBreedB2Correct: breedB2Correct,
      }
      await AsyncStorage.setItem("mbBreeds", JSON.stringify(mbBreeds))
      await AsyncStorage.setItem("mbBreedsCorrect", JSON.stringify(mbBreedsCorrect))
      await AsyncStorage.setItem("mbLastTimestamp", Date.now())
      await AsyncStorage.setItem("mbLastGuessRound", 2)
    } catch (e) {
      console.log(e)
    }
  }

  const saveThirdRoundGuessesToLS = async () => {
    try {
      const mbBreeds = {
        mbBreedA1: breedA1,
        mbBreedB1: breedB1,
        mbBreedA2: breedA2,
        mbBreedB2: breedB2,
        mbBreedA3: breedA3,
        mbBreedB3: breedB3,
      }
      const mbBreedsCorrect = {
        mbBreedA1Correct: breedA1Correct,
        mbBreedB1Correct: breedB1Correct,
        mbBreedA2Correct: breedA2Correct,
        mbBreedB2Correct: breedB2Correct,
        mbBreedA3Correct: breedA3Correct,
        mbBreedB3Correct: breedB3Correct,
      }

      await AsyncStorage.setItem("mbBreeds", JSON.stringify(mbBreeds))
      await AsyncStorage.setItem("mbBreedsCorrect", JSON.stringify(mbBreedsCorrect))
      await AsyncStorage.setItem("mbLastTimestamp", Date.now())
      await AsyncStorage.setItem("mbLastGuessRound", 3)
    } catch (e) {
      console.log(e)
    }
  }

  const saveFourthRoundGuessesToLS = async () => {
    try {
      const mbBreeds = {
        mbBreedA1: breedA1,
        mbBreedB1: breedB1,
        mbBreedA2: breedA2,
        mbBreedB2: breedB2,
        mbBreedA3: breedA3,
        mbBreedB3: breedB3,
        mbBreedA4: breedA4,
        mbBreedB4: breedB4,
      }
      const mbBreedsCorrect = {
        mbBreedA1Correct: breedA1Correct,
        mbBreedB1Correct: breedB1Correct,
        mbBreedA2Correct: breedA2Correct,
        mbBreedB2Correct: breedB2Correct,
        mbBreedA3Correct: breedA3Correct,
        mbBreedB3Correct: breedB3Correct,
        mbBreedA4Correct: breedA4Correct,
        mbBreedB4Correct: breedB4Correct,
      }

      await AsyncStorage.setItem("mbBreeds", JSON.stringify(mbBreeds))
      await AsyncStorage.setItem("mbBreedsCorrect", JSON.stringify(mbBreedsCorrect))
      await AsyncStorage.setItem("mbLastTimestamp", Date.now())
      await AsyncStorage.setItem("mbLastGuessRound", 4)
    } catch (e) {
      console.log(e)
    }
  }

  const saveFifthRoundGuessesToLS = async () => {
    try {
      const mbBreeds = {
        mbBreedA1: breedA1,
        mbBreedB1: breedB1,
        mbBreedA2: breedA2,
        mbBreedB2: breedB2,
        mbBreedA3: breedA3,
        mbBreedB3: breedB3,
        mbBreedA4: breedA4,
        mbBreedB4: breedB4,
        mbBreedA5: breedA5,
        mbBreedB5: breedB5,
      }
      const mbBreedsCorrect = {
        mbBreedA1Correct: breedA1Correct,
        mbBreedB1Correct: breedB1Correct,
        mbBreedA2Correct: breedA2Correct,
        mbBreedB2Correct: breedB2Correct,
        mbBreedA3Correct: breedA3Correct,
        mbBreedB3Correct: breedB3Correct,
        mbBreedA4Correct: breedA4Correct,
        mbBreedB4Correct: breedB4Correct,
        mbBreedA5Correct: breedA5Correct,
        mbBreedB5Correct: breedB5Correct,
      }

      await AsyncStorage.setItem("mbBreeds", JSON.stringify(mbBreeds))
      await AsyncStorage.setItem("mbBreedsCorrect", JSON.stringify(mbBreedsCorrect))
      await AsyncStorage.setItem("mbLastTimestamp", Date.now())
      await AsyncStorage.setItem("mbLastGuessRound", 5)
    } catch (e) {
      console.log(e)
    }
  }

  const saveMBWinToLS = async (value) => {
    try {
      await AsyncStorage.setItem("mbGameWon", value)
    } catch (e) {
      console.log(e)
    }
  }

  const showCopyrightInfo = () => {
    setShowCopyright((prevState) => !prevState)
    logEvent(analytics, 'show_copyright')
  }

  const hideCopyrightInfo = () => {
    setShowCopyright((prevState) => !prevState)
    logEvent(analytics, 'hide_copyright')
  }

  const saveToFaves = async () => {
    try {
      const result = await AsyncStorage.getItem("faves")
      const id = [imageURL]
      if (result !== null) {
        let newIds = JSON.parse(result).concat(id)
        AsyncStorage.setItem("faves", JSON.stringify(newIds))
        setFavourited(true)
      } else {
        AsyncStorage.setItem("faves", JSON.stringify(id))
        setFavourited(true)
      }
    } catch (e) {
      console.log(e)
    }
    logEvent(analytics, 'save_to_favourites')
  }

  const removeFromFaves = async () => {
    try {
      const result = await AsyncStorage.getItem("faves")
      const id = imageURL
      if (result !== null) {
        let newIds = JSON.parse(result).filter((item) => item !== id)
        AsyncStorage.setItem("faves", JSON.stringify(newIds))
        setFavourited(false)
      } else {
        setFavourited(false)
      }
    } catch (e) {
      console.log(e)
    }
    logEvent(analytics, 'remove_from_favourites')
  }

  const toggleFaves = () => {
    !favourited ? saveToFaves() : removeFromFaves()
  }

  const checkOneGuess = (guess, previousGuesses, setGuessFunc, gaString) => {
    if (guess === null) {
      showMessage({ message: "Please ensure field is not empty", type: "info", style: { textAlign: 'center' } })
      return
    } else if (previousGuesses.includes(guess)) {
      showMessage({ message: "Please ensure breed is not the same as a previous guess", type: "info", style: { textAlign: 'center' } })
      return
    }
    // check if both guess is contained in breed array = winner
    if (breeds.includes(guess)) {
      setGuessFunc(true)
      setGameWon(true)
      setLastGuessRound(lastGuessRound + 1)
      logEvent(analytics, `MB_guess_${gaString}_correct`)
      return
    }
    setGuessFunc(false)
    setLastGuessRound(lastGuessRound + 1)
    logEvent(analytics, `MB_guess_${gaString}_incorrect`)
  }

  const checkTwoGuesses = (guessA, guessB, previousGuesses, setGuessAFunc, setGuessBFunc, gaString1, gaString2) => {
    if (guessA === null || guessB === null) {
      showMessage({ message: "Please ensure both fields are completed", type: "info", style: { textAlign: 'center' } })
      return
    } else if (guessA === guessB) {
      showMessage({ message: "Please ensure breeds are different", type: "info", style: { textAlign: 'center' } })
      return
    } else if (previousGuesses.includes(guessA) || previousGuesses.includes(guessB)) {
      showMessage({ message: "Please ensure breed is not the same as a previous guess", type: "info", style: { textAlign: 'center' } })
      return
    }

    if (gaString1 === 'A1' && gaString1 === 'B1') {
      logEvent(analytics, 'MB_game_started')
    }

    // check if both guessA and guessB are contained in breed array = winner
    if (breeds.includes(guessA) && breeds.includes(guessB)) {
      setGuessAFunc(true)
      setGuessBFunc(true)
      setGameWon(true)
      setLastGuessRound(lastGuessRound + 1)
      logEvent(analytics, `MB_guess_${gaString1}_correct`)
      logEvent(analytics, `MB_guess_${gaString2}_correct`)
      return
    }
    // check if guessA is contained in breed array = guessA correct
    if (breeds.includes(guessA) && !breeds.includes(guessB)) {
      setGuessAFunc(true)
      setGuessBFunc(false)
      setLastGuessRound(lastGuessRound + 1)
      logEvent(analytics, `MB_guess_${gaString1}_correct`)
      logEvent(analytics, `MB_guess_${gaString2}_incorrect`)
      return
    }

    // check if breed 2 is contained in breed array = breed 2 correct
    if (!breeds.includes(guessA) && breeds.includes(guessB)) {
      setGuessAFunc(false)
      setGuessBFunc(true)
      setLastGuessRound(lastGuessRound + 1)
      logEvent(analytics, `MB_guess_${gaString1}_incorrect`)
      logEvent(analytics, `MB_guess_${gaString2}_correct`)
      return
    }

    setGuessAFunc(false)
    setGuessBFunc(false)
    setLastGuessRound(lastGuessRound + 1)
    logEvent(analytics, `MB_guess_${gaString1}_incorrect`)
    logEvent(analytics, `MB_guess_${gaString2}_incorrect`)
  }

  const checkGuessFull = () => {
    // validate guess is a correctly typed breed func to go here?
    // split breed at space (' ') and capitalise first letter, check if that breed is contained in breeds array
    // if breed hasn't been selected using the normal way, and only submitted via typing, then do this validation function first etc etc
    // no need to run saveGuessesToLS in this function - that is done by the lastGuessRound useEffect
    // *******
    if (lastGuessRound === 0) {
      checkTwoGuesses(breedA1, breedB1, [], setBreedA1Correct, setBreedB1Correct, 'A1', 'B1')
    } else if (lastGuessRound === 1) {
      if (breedA1Correct === true) {
        checkOneGuess(breedB2, [breedA1, breedB1], setBreedB2Correct, 'B2')
        return
      }
      if (breedB1Correct === true) {
        checkOneGuess(breedA2, [breedA1, breedB1], setBreedA2Correct, 'A2')
        return
      }
      checkTwoGuesses(breedA2, breedB2, [breedA1, breedB1], setBreedA2Correct, setBreedB2Correct, 'A2', 'B2')

    } else if (lastGuessRound === 2) {
      if (breedA1Correct === true) {
        checkOneGuess(breedB3, [breedA1, breedB1, breedA2, breedB2], setBreedB3Correct, 'B3')
        return
      }
      if (breedB1Correct === true) {
        checkOneGuess(breedA3, [breedA1, breedB1, breedA2, breedB2], setBreedA3Correct, 'A3')
        return
      }

      if (breedA2Correct === true) {
        checkOneGuess(breedB3, [breedA1, breedB1, breedA2, breedB2], setBreedB3Correct, 'B3')
        return
      }
      if (breedB2Correct === true) {
        checkOneGuess(breedA3, [breedA1, breedB1, breedA2, breedB2], setBreedA3Correct, 'A3')
        return
      }
      checkTwoGuesses(breedA3, breedB3, [breedA1, breedB1, breedA2, breedB2], setBreedA3Correct, setBreedB3Correct, 'A3', 'B3')


    } else if (lastGuessRound === 3) {
      if (breedA1Correct === true) {
        checkOneGuess(breedB4, [breedA1, breedB1, breedA2, breedB2, breedA3, breedB3], setBreedB4Correct, 'B4')
        return
      }
      if (breedB1Correct === true) {
        checkOneGuess(breedA4, [breedA1, breedB1, breedA2, breedB2, breedA3, breedB3], setBreedA4Correct, 'A4')
        return
      }
      if (breedA2Correct === true) {
        checkOneGuess(breedB4, [breedA1, breedB1, breedA2, breedB2, breedA3, breedB3], setBreedB4Correct, 'B4')
        return
      }
      if (breedB2Correct === true) {
        checkOneGuess(breedA4, [breedA1, breedB1, breedA2, breedB2, breedA3, breedB3], setBreedA4Correct, 'A4')
        return
      }
      if (breedA3Correct === true) {
        checkOneGuess(breedB4, [breedA1, breedB1, breedA2, breedB2, breedA3, breedB3], setBreedB4Correct, 'B4')
        return
      }
      if (breedB3Correct === true) {
        checkOneGuess(breedA4, [breedA1, breedB1, breedA2, breedB2, breedA3, breedB3], setBreedA4Correct, 'A4')
        return
      }
      checkTwoGuesses(breedA4, breedB4, [breedA1, breedB1, breedA2, breedB2, breedA3, breedB3], setBreedA4Correct, setBreedB4Correct, 'A4', 'B4')

    } else if (lastGuessRound === 4) {
      if (breedA1Correct === true) {
        checkOneGuess(breedB5, [breedA1, breedB1, breedA2, breedB2, breedA3, breedB3, breedA4, breedB4], setBreedB5Correct, 'B5')
        return
      }
      if (breedB1Correct === true) {
        checkOneGuess(breedA5, [breedA1, breedB1, breedA2, breedB2, breedA3, breedB3, breedA4, breedB4], setBreedA5Correct, 'A5')
        return
      }
      if (breedA2Correct === true) {
        checkOneGuess(breedB5, [breedA1, breedB1, breedA2, breedB2, breedA3, breedB3, breedA4, breedB4], setBreedB5Correct, 'B5')
        return
      }
      if (breedB2Correct === true) {
        checkOneGuess(breedA5, [breedA1, breedB1, breedA2, breedB2, breedA3, breedB3, breedA4, breedB4], setBreedA5Correct, 'A5')
        return
      }
      if (breedA3Correct === true) {
        checkOneGuess(breedB5, [breedA1, breedB1, breedA2, breedB2, breedA3, breedB3, breedA4, breedB4], setBreedB5Correct, 'B5')
        return
      }
      if (breedB3Correct === true) {
        checkOneGuess(breedA5, [breedA1, breedB1, breedA2, breedB2, breedA3, breedB3, breedA4, breedB4], setBreedA5Correct, 'A5')
        return
      }
      if (breedA4Correct === true) {
        checkOneGuess(breedB5, [breedA1, breedB1, breedA2, breedB2, breedA3, breedB3, breedA4, breedB4], setBreedB5Correct, 'B5')
        return
      }
      if (breedB4Correct === true) {
        checkOneGuess(breedA5, [breedA1, breedB1, breedA2, breedB2, breedA3, breedB3, breedA4, breedB4], setBreedA5Correct, 'A5')
        return
      }
      checkTwoGuesses(breedA5, breedB5, [breedA1, breedB1, breedA2, breedB2, breedA3, breedB3, breedA4, breedB4], setBreedA5Correct, setBreedB5Correct, 'A5', 'B5')
    }
    logEvent(analytics, 'submit_guess_mixed_breed')
  }

  const openSubmitDogForm = async () => {
    logEvent(analytics, 'open_submit_dog_form')
    await WebBrowser.openBrowserAsync('https://tally.so/r/waQJ9E')
  }

  const revealAnswer = () => {
    logEvent(analytics, 'mixed_breed_reveal_answer')
    if (lastGuessRound === 0) {
      setBreedA1('-')
      setBreedA2('-')
      setBreedA3('-')
      setBreedA4('-')
      setBreedA5('-')
      setBreedB1('-')
      setBreedB2('-')
      setBreedB3('-')
      setBreedB4('-')
      setBreedB5('-')
      setBreedA1Correct(false)
      setBreedA2Correct(false)
      setBreedA3Correct(false)
      setBreedA4Correct(false)
      setBreedA5Correct(false)
      setBreedB1Correct(false)
      setBreedB2Correct(false)
      setBreedB3Correct(false)
      setBreedB4Correct(false)
      setBreedB5Correct(false)
      setLastGuessRound(5)

    } else if (lastGuessRound === 1) {
      setBreedA2('-')
      setBreedA3('-')
      setBreedA4('-')
      setBreedA5('-')

      setBreedB2('-')
      setBreedB3('-')
      setBreedB4('-')
      setBreedB5('-')

      setBreedA2Correct(false)
      setBreedA3Correct(false)
      setBreedA4Correct(false)
      setBreedA5Correct(false)

      setBreedB2Correct(false)
      setBreedB3Correct(false)
      setBreedB4Correct(false)
      setBreedB5Correct(false)
      setLastGuessRound(5)

    } else if (lastGuessRound === 2) {

      setBreedA3('-')
      setBreedA4('-')
      setBreedA5('-')

      setBreedB3('-')
      setBreedB4('-')
      setBreedB5('-')

      setBreedA3Correct(false)
      setBreedA4Correct(false)
      setBreedA5Correct(false)

      setBreedB3Correct(false)
      setBreedB4Correct(false)
      setBreedB5Correct(false)
      setLastGuessRound(5)

    } else if (lastGuessRound === 3) {

      setBreedA4('-')
      setBreedA5('-')

      setBreedB4('-')
      setBreedB5('-')

      setBreedA4Correct(false)
      setBreedA5Correct(false)

      setBreedB4Correct(false)
      setBreedB5Correct(false)
      setLastGuessRound(5)

    } else if (lastGuessRound === 4) {
      setBreedA5('-')

      setBreedB5('-')

      setBreedA5Correct(false)

      setBreedB5Correct(false)
      setLastGuessRound(5)
    }
  }


  return (
    <View style={styles.container}>
      {isLoading === true ? (
        <ActivityIndicator size="large" />
      ) : (
        <View style={styles.imageContainer}>
          <Image
            style={styles.imageBox}
            resizeMode={imageResizeMode !== undefined ? imageResizeMode : "cover"}
            source={{
              uri: imageURL,
            }}
          />

          <View style={styles.imageButtons}>
            {/* {showCopyright ? (
              <TouchableOpacity
                onPress={() => hideCopyrightInfo()}
              >
                <Image
                  style={{ height: 20, width: 20, marginVertical: 2 }}
                  resizeMode="contain"
                  source={require("../assets/copyright-solid.png")}
                />
              </TouchableOpacity>
            ) : (
              <TouchableOpacity
                onPress={() => showCopyrightInfo()}
              >
                <Image
                  style={{ height: 20, width: 20, marginVertical: 2 }}
                  resizeMode="contain"
                  source={require("../assets/copyright-regular.png")}
                />
              </TouchableOpacity>
            )} */}

            <TouchableOpacity onPress={toggleFaves}>
              {favourited ? (
                <Image
                  style={{ height: 20, width: 20, marginVertical: 2 }}
                  resizeMode="contain"
                  source={require("../assets/heart-white.png")}
                />
              ) : (
                <Image
                  style={{ height: 20, width: 20, marginVertical: 2 }}
                  resizeMode="contain"
                  source={require("../assets/heart-outline-white.png")}
                />
              )}
            </TouchableOpacity>
          </View>

          {
            // showCopyright ? (
            imageOwner.length === 1 ?
              <View style={styles.copyrightMessage}>
                <Text style={{ color: "white", fontSize: 10 }}>Photo by </Text>
                <TouchableOpacity onPress={openImageOwner}>
                  <Text
                    style={{
                      color: "white",
                      fontSize: 10,
                      textDecorationLine: "underline",
                    }}
                  >
                    {imageOwner[0].substring(21)}
                  </Text>
                </TouchableOpacity>
              </View>
              :
              <View style={styles.copyrightMessage}>
                <Text style={{ color: "white", fontSize: 10 }}>{imageOwner[1]} (</Text>
                <TouchableOpacity onPress={openSubmitDogForm}>
                  <Text
                    style={{
                      color: "white",
                      fontSize: 10,
                      textDecorationLine: "underline",
                    }}
                  >
                    shared
                  </Text>
                </TouchableOpacity>
                <Text style={{ color: "white", fontSize: 10 }}> by {imageOwner[0]})</Text>
              </View>
            // ) : null
          }
        </View>
      )}

      {/* ************ GUESS BOX AREA START ************ */}

      <View style={{ marginVertical: 6, alignItems: "center" }}>
        <View style={styles.inputArea}>
          <View>
            <View style={styles.guessBox}>
              <AutoCompleteMB_A1
                breed={breedA1}
                handleSelect={handleSelectA1}
                guessedCorrectly={breedA1Correct}
                gameEnded={gameWon}
              />
            </View>

            {lastGuessRound >= 1 ? (
              <View style={styles.guessBox}>
                {breedA1Correct ? (
                  <AutoCompleteMB_Empty />
                ) : (
                  <AutoCompleteMB_A2
                    breed={breedA2}
                    handleSelect={handleSelectA2}
                    guessedCorrectly={breedA2Correct}
                    gameEnded={gameWon}
                  />
                )}
              </View>
            ) : (
              <View style={styles.guessBox}>
                <AutoCompleteMB_Empty />
              </View>
            )}

            {lastGuessRound >= 2 ? (
              <View style={styles.guessBox}>
                {breedA1Correct || breedA2Correct ? (
                  <AutoCompleteMB_Empty />
                ) : (
                  <AutoCompleteMB_A3
                    breed={breedA3}
                    handleSelect={handleSelectA3}
                    guessedCorrectly={breedA3Correct}
                    gameEnded={gameWon}
                  />
                )}
              </View>
            ) : (
              <View style={styles.guessBox}>
                <AutoCompleteMB_Empty />
              </View>
            )}

            {lastGuessRound >= 3 ? (
              <View style={styles.guessBox}>
                {breedA1Correct || breedA2Correct || breedA3Correct ? (
                  <AutoCompleteMB_Empty />
                ) : (
                  <AutoCompleteMB_A4
                    breed={breedA4}
                    handleSelect={handleSelectA4}
                    guessedCorrectly={breedA4Correct}
                    gameEnded={gameWon}
                  />
                )}
              </View>
            ) : (
              <View style={styles.guessBox}>
                <AutoCompleteMB_Empty />
              </View>
            )}

            {lastGuessRound >= 4 ? (
              <View style={styles.guessBox}>
                {breedA1Correct ||
                  breedA2Correct ||
                  breedA3Correct ||
                  breedA4Correct ? (
                  <AutoCompleteMB_Empty />
                ) : (
                  <AutoCompleteMB_A5
                    breed={breedA5}
                    handleSelect={handleSelectA5}
                    guessedCorrectly={breedA5Correct}
                    gameEnded={gameWon}
                  />
                )}
              </View>
            ) : (
              <View style={styles.guessBox}>
                <AutoCompleteMB_Empty />
              </View>
            )}
          </View>

          <View>
            <View style={styles.guessBox}>
              <AutoCompleteMB_B1
                breed={breedB1}
                handleSelect={handleSelectB1}
                guessedCorrectly={breedB1Correct}
                gameEnded={gameWon}
              />
            </View>

            {lastGuessRound >= 1 ? (
              <View style={styles.guessBox}>
                {breedB1Correct ? (
                  <AutoCompleteMB_Empty />
                ) : (
                  <AutoCompleteMB_B2
                    breed={breedB2}
                    handleSelect={handleSelectB2}
                    guessedCorrectly={breedB2Correct}
                    gameEnded={gameWon}
                  />
                )}
              </View>
            ) : (
              <View style={styles.guessBox}>
                <AutoCompleteMB_Empty />
              </View>
            )}

            {lastGuessRound >= 2 ? (
              <View style={styles.guessBox}>
                {breedB1Correct || breedB2Correct ? (
                  <AutoCompleteMB_Empty />
                ) : (
                  <AutoCompleteMB_B3
                    breed={breedB3}
                    handleSelect={handleSelectB3}
                    guessedCorrectly={breedB3Correct}
                    gameEnded={gameWon}
                  />
                )}
              </View>
            ) : (
              <View style={styles.guessBox}>
                <AutoCompleteMB_Empty />
              </View>
            )}

            {lastGuessRound >= 3 ? (
              <View style={styles.guessBox}>
                {breedB1Correct || breedB2Correct || breedB3Correct ? (
                  <AutoCompleteMB_Empty />
                ) : (
                  <AutoCompleteMB_B4
                    breed={breedB4}
                    handleSelect={handleSelectB4}
                    guessedCorrectly={breedB4Correct}
                    gameEnded={gameWon}
                  />
                )}
              </View>
            ) : (
              <View style={styles.guessBox}>
                <AutoCompleteMB_Empty />
              </View>
            )}

            {lastGuessRound >= 4 ? (
              <View style={styles.guessBox}>
                {breedB1Correct ||
                  breedB2Correct ||
                  breedB3Correct ||
                  breedB4Correct ? (
                  <AutoCompleteMB_Empty />
                ) : (
                  <AutoCompleteMB_B5
                    breed={breedB5}
                    handleSelect={handleSelectB5}
                    guessedCorrectly={breedB5Correct}
                    gameEnded={gameWon}
                  />
                )}
              </View>
            ) : (
              <View style={styles.guessBox}>
                <AutoCompleteMB_Empty />
              </View>
            )}
          </View>
        </View>

        {/* ************ GUESS BOX AREA END ************ */}

        {gameWon === null ? (
          <>
            <View>
              <Pressable onPress={checkGuessFull} style={styles.button}>
                <Text style={{ fontSize: 16, fontWeight: 600, color: "white" }}>
                  Submit
                </Text>
              </Pressable>
            </View>

            <View>
              <Pressable onPress={() => setRevealAnswerModalVisible(true)} style={styles.secondaryButton}>
                <Text style={{ color: "#2C41FF" }}>
                  Reveal answer
                </Text>
              </Pressable>
            </View>

            <Modal
              transparent={true}
              visible={revealAnswerModalVisible}
              onRequestClose={() => {
                setRevealAnswerModalVisible(!revealAnswerModalVisible)
              }}>
              <View style={styles.centeredView}>
                <View style={styles.modalView}>
                  <View style={styles.modalHeader}>
                    <Pressable onPress={() => setRevealAnswerModalVisible(false)}>
                      <Image
                        style={{ height: 16, width: 16, marginRight: 15, marginTop: 15 }}
                        source={require('../assets/close.png')}
                      />
                    </Pressable>
                  </View>

                  <View style={{ paddingHorizontal: 20, paddingBottom: 20 }}>
                    <Text style={{ fontSize: 16, textAlign: 'center', marginBottom: 10 }}>Are you sure you want to see the answer? This will automatically finish today's game and update your stats.</Text>
                    <View>
                      <Pressable onPress={() => setRevealAnswerModalVisible(false)} style={styles.button}>
                        <Text style={{ color: 'white', fontSize: 16 }}>No, I'll keep playing</Text>
                      </Pressable>
                      <Pressable onPress={revealAnswer} style={{ ...styles.button, backgroundColor: 'white', borderColor: '#2C41FF', borderWidth: 1 }}>
                        <Text style={{ fontSize: 16 }}>Yes, reveal the answer</Text>
                      </Pressable>
                    </View>
                  </View>

                </View>
              </View>
            </Modal>
          </>
        ) :
          <>
            <PostGameMessage singleBreed={false} breeds={breeds} owner={imageOwner} socialMedia={imageOwner?.length > 2 ? imageOwner[3] : null} setModalVisible={setModalVisible} />

            <Modal
              transparent={true}
              visible={modalVisible}
              onRequestClose={() => {
                setModalVisible(!modalVisible)
              }}>
              <View style={styles.centeredView}>
                <View style={{ ...styles.modalView, backgroundColor: gameWon === true ? '#CBF2CA' : '#EFBDBD' }}>
                  <View style={styles.modalHeader}>

                    <Pressable onPress={closeModal}>
                      <Image
                        style={{ height: 16, width: 16, marginRight: 15, marginTop: 15 }}
                        source={require('../assets/close.png')}
                      />
                    </Pressable>
                  </View>

                  <PostGameModal gameWon={gameWon} singleBreed={false} breeds={breeds} imageOwner={imageOwner} imageURL={imageURL} imageResizeMode={imageResizeMode} socialMedia={imageOwner} nickname={nickname} />

                </View>
              </View>
            </Modal>
          </>
        }

      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    paddingTop: 10,
  },
  imageBox: {
    height: deviceSize === 'large' ? 340 : deviceSize === 'medium' ? 300 : 280,
    width: deviceSize === 'large' ? 390 : 350,
    backgroundColor: '#E7E7E7',
    borderRadius: 5
  },
  inputArea: {
    width: deviceSize === 'large' ? 390 : 350,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  guessBox: {
    width: deviceSize === 'large' ? 190 : 170
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#2C41FF",
    borderRadius: 20,
    borderColor: "gray",
    height: 35,
    padding: 20,
    marginTop: 5,
  },
  secondaryButton: {
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    borderWidth: 1,
    borderColor: "#2C41FF",
    height: 25,
    padding: 10,
    marginTop: 30
  },
  lives: {
    flexDirection: "row",
    marginTop: 20,
  },
  imageButtons: {
    marginRight: 5,
    marginBottom: 5,
    position: "absolute",
    bottom: 0,
    right: 0,
    alignItems: "center",
    justifyContent: "space-around",
    backgroundColor: "rgba(0,0,0,0.4)",
    padding: 5,
    borderRadius: 7,
  },
  copyrightMessage: {
    flexDirection: "row",
    marginLeft: 5,
    marginBottom: 5,
    position: "absolute",
    bottom: 0,
    left: 0,
    alignItems: "center",
    justifyContent: "space-around",
    backgroundColor: "rgba(0,0,0,0.4)",
    padding: 5,
    borderRadius: 7,
  },
  // Modal styles
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: 'rgba(0,0,0,0.5)'
    backgroundColor: 'rgba(0,0,0,0.75)'
  },
  modalView: {
    width: '85%',
    maxWidth: 600,
    backgroundColor: 'white',
    // backgroundColor: '#0011A7',
    // backgroundColor: '#B4EFB2',
    // borderRadius: 20,
    borderRadius: 10,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    overflow: 'hidden'
  },
  modalHeader: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    // backgroundColor: 'lightblue',
    alignItems: 'center',
    // padding: 15,
    // borderBottomWidth: 0.5,
    // borderBottomColor: 'lightgray',
  }
})
