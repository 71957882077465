const breeds = [
    { id: "1", title: "Affenpinscher" },
    { id: "2", title: "Afghan Hound" },
    { id: "3", title: "African" },
    { id: "4", title: "Airedale" },
    { id: "5", title: "Akita" },
    { id: "6", title: "American Terrier" },
    { id: "7", title: "Appenzeller" },
    { id: "8", title: "Australian Cattledog" },
    { id: "9", title: "Australian Shepherd" },
    { id: "10", title: "Australian Terrier" },
    { id: "11", title: "Barak Hound" },
    { id: "12", title: "Basenji" },
    { id: "13", title: "Basset Hound" },
    { id: "14", title: "Beagle" },
    { id: "15", title: "Bedlington Terrier" },
    { id: "16", title: "Bernese Mountain" },
    { id: "17", title: "Bichon Frise" },
    { id: "18", title: "Blenheim Spaniel" },
    { id: "19", title: "Blood Hound" },
    { id: "20", title: "Bluetick" },
    { id: "21", title: "Border Collie" },
    { id: "22", title: "Border Terrier" },
    { id: "23", title: "Borzoi" },
    { id: "24", title: "Boston Terrier" },
    { id: "25", title: "Bouvier" },
    { id: "26", title: "Boxer" },
    { id: "27", title: "Brabancon" },
    { id: "28", title: "Briard" },
    { id: "29", title: "Brittany Spaniel" },
    { id: "30", title: "Bullmastiff" },
    { id: "31", title: "Bulldog" },
    { id: "32", title: "Cairn Terrier" },
    { id: "33", title: "Caucasian Ovcharka" },
    { id: "34", title: "Cavalier King Charles Spaniel" },
    { id: "35", title: "Chesapeake Retriever" },
    { id: "36", title: "Chihuahua" },
    { id: "37", title: "Chow Chow" },
    { id: "38", title: "Clumber" },
    { id: "39", title: "Cocker Spaniel" },
    { id: "40", title: "Coonhound" },
    { id: "41", title: "Corgi" },
    { id: "42", title: "Cotondetulear" },
    { id: "43", title: "Curly Retriever" },
    { id: "44", title: "Dachshund" },
    { id: "45", title: "Dalmatian" },
    { id: "46", title: "Dandie Terrier" },
    { id: "47", title: "Dhole" },
    { id: "48", title: "Dingo" },
    { id: "49", title: "Doberman" },
    { id: "50", title: "English Bull Terrier" },
    { id: "51", title: "English Hound" },
    { id: "52", title: "English Mastiff" },
    { id: "53", title: "English Setter" },
    { id: "54", title: "English Sheepdog" },
    { id: "55", title: "Entlebucher" },
    { id: "56", title: "Eskimo" },
    { id: "57", title: "Finnish Lapphund" },
    { id: "58", title: "Flatcoated Retriever" },
    { id: "59", title: "Fox Terrier" },
    { id: "60", title: "French Bulldog" },
    { id: "61", title: "German Longhaired Pointer" },
    { id: "62", title: "German Shepherd (Alsatian)" },
    { id: "63", title: "German Shorthaired Pointer" },
    { id: "64", title: "Giant Schnauzer" },
    { id: "65", title: "Golden Retriever" },
    { id: "66", title: "Gordon Setter" },
    { id: "67", title: "Great Dane" },
    { id: "68", title: "Greyhound" },
    { id: "69", title: "Groenendael" },
    { id: "70", title: "Hairless Mexican" },
    { id: "71", title: "Havanese" },
    { id: "72", title: "Huntaway" },
    { id: "73", title: "Husky" },
    { id: "74", title: "Ibizan Hound" },
    { id: "75", title: "Irish Setter" },
    { id: "76", title: "Irish Spaniel" },
    { id: "77", title: "Irish Terrier" },
    { id: "78", title: "Irish Wolfhound" },
    { id: "79", title: "Italian Greyhound" },
    { id: "80", title: "Jack Russell" },
    { id: "81", title: "Japanese Spaniel" },
    { id: "82", title: "Keeshond" },
    { id: "83", title: "Kelpie" },
    { id: "84", title: "Kerryblue Terrier" },
    { id: "85", title: "Komondor" },
    { id: "86", title: "Kuvasz" },
    { id: "87", title: "Labrador" },
    { id: "88", title: "Lakeland Terrier" },
    { id: "89", title: "Large Munsterlander" },
    { id: "90", title: "Leonberg" },
    { id: "91", title: "Lhasa" },
    { id: "92", title: "Malamute" },
    { id: "93", title: "Malinois" },
    { id: "94", title: "Maltese" },
    { id: "95", title: "Miniature Pinscher" },
    { id: "96", title: "Miniature Poodle" },
    { id: "97", title: "Miniature Schnauzer" },
    { id: "98", title: "Newfoundland" },
    { id: "99", title: "Norfolk Terrier" },
    { id: "100", title: "Norwegian Buhund" },
    { id: "101", title: "Norwegian Elkhound" },
    { id: "102", title: "Norwich Terrier" },
    { id: "103", title: "Otterhound" },
    { id: "104", title: "Papillon" },
    { id: "105", title: "Patterdale Terrier" },
    { id: "106", title: "Pekinese" },
    { id: "107", title: "Pembroke" },
    { id: "108", title: "Pitbull" },
    { id: "109", title: "Plott Hound" },
    { id: "110", title: "Polish Tatra Sheepdog" },
    { id: "111", title: "Pomeranian" },
    { id: "112", title: "Poodle" },
    { id: "113", title: "Pug" },
    { id: "114", title: "Pyrenees" },
    { id: "115", title: "Redbone" },
    { id: "116", title: "Rhodesian Ridgeback" },
    { id: "117", title: "Rottweiler" },
    { id: "118", title: "Rough Collie" },
    { id: "119", title: "Saluki" },
    { id: "120", title: "Samoyed" },
    { id: "121", title: "Schipperke" },
    { id: "122", title: "Scottish Deerhound" },
    { id: "123", title: "Scottish Terrier" },
    { id: "124", title: "Sealyham Terrier" },
    { id: "125", title: "Shetland Sheepdog" },
    { id: "126", title: "Shiba" },
    { id: "127", title: "Shihtzu" },
    { id: "128", title: "Silky Terrier" },
    { id: "129", title: "Small Munsterlander" },
    { id: "130", title: "Spanish Waterdog" },
    { id: "131", title: "Springer Spaniel" },
    { id: "132", title: "St Bernard" },
    { id: "133", title: "Staffordshire Bull Terrier" },
    { id: "134", title: "Sussex Spaniel" },
    { id: "135", title: "Swiss Mountain" },
    { id: "136", title: "Tamaskan" },
    { id: "137", title: "Tervuren" },
    { id: "138", title: "Tibetan Mastiff" },
    { id: "139", title: "Tibetan Terrier" },
    { id: "140", title: "Toy Poodle" },
    { id: "141", title: "Toy Terrier" },
    { id: "142", title: "Vizsla" },
    { id: "143", title: "Walker Hound" },
    { id: "144", title: "Weimaraner" },
    { id: "145", title: "Welsh Spaniel" },
    { id: "146", title: "Welsh Terrier" },
    { id: "147", title: "Westhighland Terrier" },
    { id: "148", title: "Wheaten Terrier" },
    { id: "149", title: "Whippet" },
    { id: "150", title: "Yorkshire Terrier" },
];

export default breeds;  