import { useState, useEffect } from "react"
import { Image, StyleSheet, Switch, Text, TextInput, TouchableOpacity, View } from "react-native"
import AsyncStorage from "@react-native-async-storage/async-storage"
import * as WebBrowser from 'expo-web-browser'
import { showMessage } from "react-native-flash-message"

import app from '../firebaseConfig'
import { getAnalytics, logEvent } from "firebase/analytics"
const analytics = getAnalytics(app)

export default function SettingsScreen(props) {
    const { passGameModesBack, saveFeedback } = props
    const [text, setText] = useState("")
    const [singleEnabled, setSingleEnabled] = useState(false)
    const [mixedEnabled, setMixedEnabled] = useState(false)
    const [toggleReportBug, setToggleReportBug] = useState(false)

    const toggleSingleBreedSwitch = async () => {
        if (singleEnabled === false) {
            setSingleEnabled((previousState) => !previousState)
        } else {
            setSingleEnabled((previousState) => !previousState)
            if (mixedEnabled === false) {
                setMixedEnabled(true)
                showMessage({ message: "At least one game mode must be enabled", type: "info", style: { textAlign: 'center' } })
            }
        }
        logEvent(analytics, 'toggle_single_breed')
    }

    const toggleMixedBreedSwitch = async () => {
        if (mixedEnabled === false) {
            setMixedEnabled((previousState) => !previousState)
        } else {
            setMixedEnabled((previousState) => !previousState)
            if (singleEnabled === false) {
                setSingleEnabled(true)
                showMessage({ message: "At least one game mode must be enabled", type: "info", style: { textAlign: 'center' } })
            }
        }
        logEvent(analytics, 'toggle_mixed_breed')
    }

    const checkSwitchLogic = async () => {
        if (singleEnabled === true && mixedEnabled == true) {
            await AsyncStorage.setItem("gameModes", JSON.stringify(['mixedBreed', 'singleBreed']))
            passGameModesBack(['mixedBreed', 'singleBreed'])
        }
        if (singleEnabled === true && mixedEnabled == false) {
            await AsyncStorage.setItem("gameModes", JSON.stringify(['singleBreed']))
            passGameModesBack(['singleBreed'])
        }
        if (singleEnabled === false && mixedEnabled == true) {
            await AsyncStorage.setItem("gameModes", JSON.stringify(['mixedBreed']))
            passGameModesBack(['mixedBreed'])
        }
    }

    const toggleBug = () => {
        setToggleReportBug(!toggleReportBug)
    }

    const getAndSetGameModesFromLS = async () => {
        try {
            const gameModes = JSON.parse(await AsyncStorage.getItem("gameModes"))
            gameModes.includes("mixedBreed") && setMixedEnabled(true)
            gameModes.includes("singleBreed") && setSingleEnabled(true)
        } catch (error) {
            console.log(error)
        }
    }

    const handleSubmitFeedback = () => {
        saveFeedback(text)
        setText("")
        showMessage({ message: "Feedback received - thanks! 👍", type: "success", position: 'top', style: { textAlign: 'center' } })
    }

    useEffect(() => {
        checkSwitchLogic()
    }, [singleEnabled, mixedEnabled])

    useEffect(() => {
        getAndSetGameModesFromLS()
        logEvent(analytics, 'SCREEN_VIEW_settings')
    }, [])

    const openSubmitDogForm = async () => {
        logEvent(analytics, 'open_submit_dog_form')
        await WebBrowser.openBrowserAsync('https://tally.so/r/waQJ9E')
    }

    const openPrivacyPolicy = async () => {
        logEvent(analytics, 'open_privacy_policy')
        await WebBrowser.openBrowserAsync('https://www.notion.so/doggle/Doggle-Privacy-Policy-607d0cc315014c358c91696a39e8ce59')
    }

    return (
        <View style={styles.container}>
            <Text style={{ marginBottom: 15, fontWeight: 600, fontSize: 24, alignSelf: 'center' }}>Settings</Text>
            <View style={styles.section} />
            <View style={{ paddingVertical: 15 }}>
                <View
                    style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Text style={{ paddingVertical: 5 }}>Single breed</Text>
                    <Switch
                        trackColor={{ false: "#767577", true: "#81b0ff" }}
                        thumbColor={singleEnabled ? "#f5dd4b" : "#f4f3f4"}
                        ios_backgroundColor="#3e3e3e"
                        onValueChange={toggleSingleBreedSwitch}
                        value={singleEnabled}
                    />
                </View>
                <View
                    style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Text style={{ paddingVertical: 5 }}>Mixed breed</Text>
                    <Switch
                        trackColor={{ false: "#767577", true: "#81b0ff" }}
                        thumbColor={mixedEnabled ? "#f5dd4b" : "#f4f3f4"}
                        ios_backgroundColor="#3e3e3e"
                        onValueChange={toggleMixedBreedSwitch}
                        value={mixedEnabled}
                    />
                </View>
            </View>

            <View style={styles.section} />

            <TouchableOpacity
                onPress={openSubmitDogForm}
                style={{
                    flexDirection: "row",
                    paddingTop: 15,
                    paddingBottom: 15,
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Text>Submit your dog to be featured</Text>
                <Image
                    style={{ height: 16, width: 16 }}
                    resizeMode="contain"
                    source={require("../assets/chevron-right-solid-grey.png")}
                />
            </TouchableOpacity>

            <View style={styles.section} />

            <TouchableOpacity
                onPress={toggleBug}
                style={{
                    flexDirection: "row",
                    paddingTop: 15,
                    paddingBottom: toggleReportBug ? 10 : 15,
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Text>Report an issue or bug</Text>
                {toggleReportBug ? (
                    <Image
                        style={{ height: 16, width: 16 }}
                        resizeMode="contain"
                        source={require("../assets/chevron-up-solid-grey.png")}
                    />
                ) : (
                    <Image
                        style={{ height: 16, width: 16 }}
                        resizeMode="contain"
                        source={require("../assets/chevron-right-solid-grey.png")}
                    />
                )}
            </TouchableOpacity>
            {toggleReportBug ? (
                <>
                    <TextInput
                        style={styles.input}
                        onChangeText={setText}
                        multiline={true}
                        value={text}
                        placeholder="Type a quick description of the issue you experienced and hit 'Submit'"
                    />
                    <TouchableOpacity
                        onPress={() => handleSubmitFeedback()}
                        style={styles.button}
                    >
                        <Text style={{
                            letterSpacing: 2,
                            fontWeight: 600,
                            color: "white",
                            fontSize: 10,
                        }}
                        >
                            SUBMIT
                        </Text>
                    </TouchableOpacity>
                </>
            ) : null}
            <View style={styles.section} />

            <TouchableOpacity
                onPress={openPrivacyPolicy}
                style={{
                    flexDirection: "row",
                    paddingTop: 15,
                    paddingBottom: 15,
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Text>Privacy Policy</Text>
                <Image
                    style={{ height: 16, width: 16 }}
                    resizeMode="contain"
                    source={require("../assets/chevron-right-solid-grey.png")}
                />
            </TouchableOpacity>

        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        width: "95%",
        paddingHorizontal: 7,
        paddingBottom: 15,
    },
    section: {
        alignSelf: "center",
        height: 1,
        width: "100%",
        backgroundColor: "lightgray",
    },
    input: {
        height: 80,
        borderWidth: 1,
        borderColor: "lightgrey",
        borderRadius: 5,
        padding: 10,
        fontSize: 12,
    },
    button: {
        alignSelf: "center",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#456990",
        borderRadius: 30,
        borderColor: "gray",
        padding: 10,
        marginTop: 5,
        marginBottom: 15,
    },
})
