import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyDxjeiPJ0SqoNp1zajs8DQoWuf4sAiiMcQ",
    authDomain: "doggle-app.firebaseapp.com",
    projectId: "doggle-app",
    storageBucket: "doggle-app.appspot.com",
    messagingSenderId: "536524247430",
    appId: "1:536524247430:web:45c9c91b0499eed9145c07",
    measurementId: "G-9FELE4CZC7"
};

const app = initializeApp(firebaseConfig);

export default app