import { useEffect, useState } from 'react'
import { Image, Pressable, Share, StyleSheet, Text, View } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import SegmentedControl from "@react-native-segmented-control/segmented-control";

import app from '../firebaseConfig'
import { getAnalytics, logEvent } from "firebase/analytics"
const analytics = getAnalytics(app)

export default function StatsScreen() {
    const [mbGamesPlayed, setMBGamesPlayed] = useState(null);
    const [mbBreedsCorrect, setMBBreedsCorrect] = useState(null);
    const [mbGuessDistribution, setMBGuessDistribution] = useState([]);
    const [sbGamesPlayed, setSBGamesPlayed] = useState(null);
    const [sbBreedsCorrect, setSBBreedsCorrect] = useState(null);
    const [sbGuessDistribution, setSBGuessDistribution] = useState([]);
    const [fact, setFact] = useState(null);
    const [dogNumber, setDogNumber] = useState(null);

    const [gameModes, setGameModes] = useState([]);
    const [gameModeIndex, setGameModeIndex] = useState(0);

    const getGameModesFromLS = async () => {
        let gameModesLS = JSON.parse(await AsyncStorage.getItem("gameModes"));
        setGameModes(gameModesLS)
    };

    useEffect(() => {
        getGameModesFromLS()
        getMBStatsFromLS()
        getSBStatsFromLS()
        getFactFromLS()
        logEvent(analytics, 'SCREEN_VIEW_stats')
    }, []);

    const getMBStatsFromLS = async () => {
        try {
            const gamesPlayedLS = JSON.parse(await AsyncStorage.getItem('mbStatsGamesPlayed'))
            const breedsCorrectLS = JSON.parse(await AsyncStorage.getItem('mbStatsBreedsCorrectlyGuessed'))
            const guessDistributionLS = JSON.parse(await AsyncStorage.getItem('mbStatsGuessDistribution'))
            setMBGamesPlayed(gamesPlayedLS === null ? 0 : gamesPlayedLS)
            setMBBreedsCorrect(breedsCorrectLS === null ? 0 : breedsCorrectLS)
            setMBGuessDistribution(guessDistributionLS === null ? [0, 0, 0] : guessDistributionLS)
        } catch (error) {
            console.log(error)
        }
    }

    const getSBStatsFromLS = async () => {
        try {
            const gamesPlayedLS = JSON.parse(await AsyncStorage.getItem('sbStatsGamesPlayed'))
            const breedsCorrectLS = JSON.parse(await AsyncStorage.getItem('sbStatsBreedsCorrectlyGuessed'))
            const guessDistributionLS = JSON.parse(await AsyncStorage.getItem('sbStatsGuessDistribution'))
            setSBGamesPlayed(gamesPlayedLS === null ? 0 : gamesPlayedLS)
            setSBBreedsCorrect(breedsCorrectLS === null ? 0 : breedsCorrectLS)
            setSBGuessDistribution(guessDistributionLS === null ? [0, 0, 0] : guessDistributionLS)
        } catch (error) {
            console.log(error)
        }
    }

    const getFactFromLS = async () => {
        try {
            const fact = JSON.parse(await AsyncStorage.getItem('fact'))
            const dogNumberLS = JSON.parse(await AsyncStorage.getItem('dogNumber'))
            setFact(fact)
            setDogNumber(dogNumberLS)
        } catch (error) {
            console.log(error)
        }
    }

    const shareStats = async () => {
        try {
            let message
            if (gameModes.length === 2 && gameModeIndex === 0 || gameModes === ['singleBreed']) {
                logEvent(analytics, 'share_stats_single_breed')
                message = `My win rate on DOGGLE is ${sbBreedsCorrect !== 0 ? String(Math.round(sbBreedsCorrect / sbGamesPlayed * 100)) : '0'}%. Give the dog guessing game a go at https://doggle.app`
            }

            if (gameModes.length === 2 && gameModeIndex === 1 || gameModes === ['mixedBreed']) {
                logEvent(analytics, 'share_stats_mixed_breed')
                message = `My win rate on DOGGLE is ${mbBreedsCorrect !== 0 ? String(Math.round(mbBreedsCorrect / (mbGamesPlayed * 2) * 100)) : '0'}%. Give the dog guessing game a go at https://doggle.app`
            }

            await Share.share({ message: message });
            // if (result.action === Share.sharedAction) {
            //     if (result.activityType) {
            //         // shared with activity type of result.activityType
            //     } else {
            //         // shared
            //     }
            if (result.action === Share.dismissedAction) {
                return
            }
            logEvent(analytics, 'challenge_friend_stats_screen')
        } catch (error) {
            console.log(error.message)
            if (error.message == 'Share is not supported in this browser') {
                alert('Sorry, sharing not supported on this browser/device')
            }
            logEvent(analytics, 'ERROR_challenge_friend_stats_screen')
        }
    }

    return (
        <>
            <View style={styles.container}>

                <Text style={{ marginBottom: 30, fontWeight: 600, fontSize: 24, alignSelf: 'center' }}>Statistics</Text>

                {gameModes !== null && gameModes.length === 2 ?
                    <>
                        <SegmentedControl
                            style={{ width: 230, alignSelf: "center", marginBottom: 15 }}
                            values={["Single Breed", "Mixed Breed"]}
                            selectedIndex={gameModeIndex}
                            onChange={(event) => {
                                setGameModeIndex(event.nativeEvent.selectedSegmentIndex);
                            }}
                        />
                        {
                            gameModeIndex === 0
                                ?
                                // <View style={{ height: 220 }}>
                                // <View style={{ height: 220 }}>
                                <View>
                                    <View style={styles.statsRow}>
                                        <View style={styles.stat}>
                                            <Text style={styles.statHeadline}>{sbGamesPlayed}</Text>
                                            <Text style={styles.statSubhead}>Played</Text>
                                        </View>

                                        <View style={styles.stat}>
                                            <Text style={styles.statHeadline}>{sbBreedsCorrect}</Text>
                                            <Text style={styles.statSubhead}>Breeds{"\n"}correct</Text>
                                        </View>

                                        <View style={styles.stat}>
                                            <Text style={styles.statHeadline}>{sbBreedsCorrect !== 0 ? String(Math.round(sbBreedsCorrect / sbGamesPlayed * 100)) : '0'}</Text>
                                            <Text style={styles.statSubhead}>Win %</Text>
                                        </View>
                                    </View>

                                    {/* <View style={styles.guessDistribution}>
                                        <Text>Distribution</Text>
                                        <Text style={{ fontSize: 10 }}>Breeds correctly guessed per game</Text>

                                        <View style={{ flexDirection: 'row', justifyContent: 'center', width: 250, marginTop: 5 }}>
                                            <View style={{ alignItems: 'center' }}>
                                                <Text style={{ height: 20, marginVertical: 5 }}>0</Text>
                                                <Text style={{ height: 20, marginVertical: 5 }}>1</Text>
                                            </View>

                                            <View style={{ marginVertical: 5, height: ('100%' - 5), width: 1, backgroundColor: 'grey', marginHorizontal: 10 }} />

                                            <View style={{ justifyContent: 'space-between', marginVertical: 5, width: 200 }}>
                                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                    <View style={{ height: 20, width: `${(sbGuessDistribution[0] / Math.max(...sbGuessDistribution) * 100)}%`, backgroundColor: 'grey' }} >
                                                        <Text style={{ alignItems: 'center', justifyContent: 'center', color: 'white', textAlign: 'right', fontWeight: 'bold', lineHeight: 20, fontSize: 14, paddingHorizontal: 5 }}>{sbGuessDistribution[0]}</Text>
                                                    </View>
                                                </View>

                                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                    <View style={{ height: 20, width: `${(sbGuessDistribution[1] / Math.max(...sbGuessDistribution) * 100)}%`, backgroundColor: 'grey' }} >
                                                        <Text style={{ alignItems: 'center', justifyContent: 'center', color: 'white', textAlign: 'right', fontWeight: 'bold', lineHeight: 20, fontSize: 14, paddingHorizontal: 5 }}>{sbGuessDistribution[1]}</Text>
                                                    </View>
                                                </View>

                                            </View>
                                        </View>
                                    </View> */}
                                </View>
                                :
                                <View>
                                    <View style={styles.statsRow}>
                                        <View style={styles.stat}>
                                            <Text style={styles.statHeadline}>{mbGamesPlayed}</Text>
                                            <Text style={styles.statSubhead}>Played</Text>
                                        </View>

                                        <View style={styles.stat}>
                                            <Text style={styles.statHeadline}>{mbBreedsCorrect}</Text>
                                            <Text style={styles.statSubhead}>Breeds{"\n"}correct</Text>
                                        </View>

                                        <View style={styles.stat}>
                                            <Text style={styles.statHeadline}>{mbBreedsCorrect !== 0 ? String(Math.round(mbBreedsCorrect / (mbGamesPlayed * 2) * 100)) : '0'}</Text>
                                            <Text style={styles.statSubhead}>Win %</Text>
                                        </View>
                                    </View>

                                    <View style={styles.guessDistribution}>
                                        <Text>Distribution</Text>
                                        <Text style={{ fontSize: 10 }}>Breeds correctly guessed per game</Text>

                                        <View style={{ flexDirection: 'row', justifyContent: 'center', width: 250, marginTop: 5 }}>
                                            <View style={{ alignItems: 'center' }}>
                                                <Text style={{ height: 20, marginVertical: 5 }}>0</Text>
                                                <Text style={{ height: 20, marginVertical: 5 }}>1</Text>
                                                <Text style={{ height: 20, marginVertical: 5 }}>2</Text>
                                            </View>

                                            <View style={{ marginVertical: 5, height: ('100%' - 5), width: 1, backgroundColor: 'grey', marginHorizontal: 10 }} />

                                            <View style={{ justifyContent: 'space-between', marginVertical: 5, width: 200 }}>
                                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                    <View style={{ height: 20, width: `${(mbGuessDistribution[0] / Math.max(...mbGuessDistribution) * 100)}%`, backgroundColor: 'grey' }} >
                                                        <Text style={{ alignItems: 'center', justifyContent: 'center', color: 'white', textAlign: 'right', fontWeight: 'bold', lineHeight: 20, fontSize: 14, paddingHorizontal: 5 }}>{mbGuessDistribution[0]}</Text>
                                                    </View>
                                                </View>

                                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                    <View style={{ height: 20, width: `${(mbGuessDistribution[1] / Math.max(...mbGuessDistribution) * 100)}%`, backgroundColor: 'grey' }} >
                                                        <Text style={{ alignItems: 'center', justifyContent: 'center', color: 'white', textAlign: 'right', fontWeight: 'bold', lineHeight: 20, fontSize: 14, paddingHorizontal: 5 }}>{mbGuessDistribution[1]}</Text>
                                                    </View>
                                                </View>

                                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                    <View style={{ height: 20, width: `${(mbGuessDistribution[2] / Math.max(...mbGuessDistribution) * 100)}%`, backgroundColor: 'grey' }} >
                                                        <Text style={{ alignItems: 'center', justifyContent: 'center', color: 'white', textAlign: 'right', fontWeight: 'bold', lineHeight: 20, fontSize: 14, paddingHorizontal: 5 }}>{mbGuessDistribution[2]}</Text>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                        }
                    </>

                    :

                    gameModes.length === 1 && gameModes.includes('mixedBreed')
                        ?
                        // <View style={{ height: 220 }}>
                        <View>
                            <View style={styles.statsRow}>
                                <View style={styles.stat}>
                                    <Text style={styles.statHeadline}>{mbGamesPlayed}</Text>
                                    <Text style={styles.statSubhead}>Played</Text>
                                </View>

                                <View style={styles.stat}>
                                    <Text style={styles.statHeadline}>{mbBreedsCorrect}</Text>
                                    <Text style={styles.statSubhead}>Breeds{"\n"}correct</Text>
                                </View>

                                <View style={styles.stat}>
                                    <Text style={styles.statHeadline}>{mbBreedsCorrect !== 0 ? String(Math.round(mbBreedsCorrect / (mbGamesPlayed * 2) * 100)) : '0'}</Text>
                                    <Text style={styles.statSubhead}>Win %</Text>
                                </View>
                            </View>

                            <View style={styles.guessDistribution}>
                                <Text>Distribution</Text>
                                <Text style={{ fontSize: 10 }}>Breeds correctly guessed per game</Text>

                                <View style={{ flexDirection: 'row', justifyContent: 'center', width: 250, marginTop: 5 }}>
                                    <View style={{ alignItems: 'center' }}>
                                        <Text style={{ height: 20, marginVertical: 5 }}>0</Text>
                                        <Text style={{ height: 20, marginVertical: 5 }}>1</Text>
                                        <Text style={{ height: 20, marginVertical: 5 }}>2</Text>
                                    </View>

                                    <View style={{ marginVertical: 5, height: ('100%' - 5), width: 1, backgroundColor: 'grey', marginHorizontal: 10 }} />

                                    <View style={{ justifyContent: 'space-between', marginVertical: 5, width: 200 }}>
                                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                            <View style={{ height: 20, width: `${(mbGuessDistribution[0] / Math.max(...mbGuessDistribution) * 100)}%`, backgroundColor: 'grey' }} >
                                                <Text style={{ alignItems: 'center', justifyContent: 'center', color: 'white', textAlign: 'right', fontWeight: 'bold', lineHeight: 20, fontSize: 14, paddingHorizontal: 5 }}>{mbGuessDistribution[0]}</Text>
                                            </View>
                                        </View>

                                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                            <View style={{ height: 20, width: `${(mbGuessDistribution[1] / Math.max(...mbGuessDistribution) * 100)}%`, backgroundColor: 'grey' }} >
                                                <Text style={{ alignItems: 'center', justifyContent: 'center', color: 'white', textAlign: 'right', fontWeight: 'bold', lineHeight: 20, fontSize: 14, paddingHorizontal: 5 }}>{mbGuessDistribution[1]}</Text>
                                            </View>
                                        </View>

                                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                            <View style={{ height: 20, width: `${(mbGuessDistribution[2] / Math.max(...mbGuessDistribution) * 100)}%`, backgroundColor: 'grey' }} >
                                                <Text style={{ alignItems: 'center', justifyContent: 'center', color: 'white', textAlign: 'right', fontWeight: 'bold', lineHeight: 20, fontSize: 14, paddingHorizontal: 5 }}>{mbGuessDistribution[2]}</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>
                        :
                        // <View style={{ height: 220 }}>
                        <View>
                            <View style={styles.statsRow}>
                                <View style={styles.stat}>
                                    <Text style={styles.statHeadline}>{sbGamesPlayed}</Text>
                                    <Text style={styles.statSubhead}>Played</Text>
                                </View>

                                <View style={styles.stat}>
                                    <Text style={styles.statHeadline}>{sbBreedsCorrect}</Text>
                                    <Text style={styles.statSubhead}>Breeds{"\n"}correct</Text>
                                </View>

                                <View style={styles.stat}>
                                    <Text style={styles.statHeadline}>{sbBreedsCorrect !== 0 ? String(Math.round(sbBreedsCorrect / sbGamesPlayed * 100)) : '0'}</Text>
                                    <Text style={styles.statSubhead}>Win %</Text>
                                </View>
                            </View>

                            {/* <View style={styles.guessDistribution}>
                                <Text>Distribution</Text>
                                <Text style={{ fontSize: 10 }}>Breeds correctly guessed per game</Text>

                                <View style={{ flexDirection: 'row', justifyContent: 'center', width: 250, marginTop: 5 }}>
                                    <View style={{ alignItems: 'center' }}>
                                        <Text style={{ height: 20, marginVertical: 5 }}>0</Text>
                                        <Text style={{ height: 20, marginVertical: 5 }}>1</Text>
                                    </View>

                                    <View style={{ marginVertical: 5, height: ('100%' - 5), width: 1, backgroundColor: 'grey', marginHorizontal: 10 }} />

                                    <View style={{ justifyContent: 'space-between', marginVertical: 5, width: 200 }}>
                                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                            <View style={{ height: 20, width: `${(sbGuessDistribution[0] / Math.max(...sbGuessDistribution) * 100)}%`, backgroundColor: 'grey' }} >
                                                <Text style={{ alignItems: 'center', justifyContent: 'center', color: 'white', textAlign: 'right', fontWeight: 'bold', lineHeight: 20, fontSize: 14, paddingHorizontal: 5 }}>{sbGuessDistribution[0]}</Text>
                                            </View>
                                        </View>

                                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                            <View style={{ height: 20, width: `${(sbGuessDistribution[1] / Math.max(...sbGuessDistribution) * 100)}%`, backgroundColor: 'grey' }} >
                                                <Text style={{ alignItems: 'center', justifyContent: 'center', color: 'white', textAlign: 'right', fontWeight: 'bold', lineHeight: 20, fontSize: 14, paddingHorizontal: 5 }}>{sbGuessDistribution[1]}</Text>
                                            </View>
                                        </View>

                                    </View>
                                </View>
                            </View> */}
                        </View>
                }


                <View style={styles.guessDistribution}>
                    <Pressable onPress={shareStats} style={{ ...styles.button, flexDirection: 'row' }}>
                        <Image source={require('../assets/share-white.png')} resizeMode="contain" style={{ width: 20, height: 20, marginRight: 10 }} />
                        <Text style={{ color: 'white', fontWeight: 600, fontSize: 16 }}>Share</Text>
                    </Pressable>
                </View>
            </View>
            <View style={{ padding: 11, alignItems: 'center', justifyContent: 'center', borderTopWidth: 1, borderTopColor: 'lightgray', width: '100%' }}>
                <Text style={{ textAlign: 'center', fontSize: 10, fontStyle: 'italic' }}>
                    {/* Daily DOGGLE Fact #{dogNumber}: {fact} */}
                    Daily Fact: {fact}
                </Text>
            </View>
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        width: '85%',
        maxWidth: 600,
        marginBottom: 30,
    },
    statsRow: {
        marginVertical: 10,
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'flex-start'
    },
    stat: {
        alignItems: 'center',
        justifyContent: 'center'
    },
    statHeadline: {
        fontSize: 26,
        fontWeight: '600',
        textAlign: 'center'
    },
    statSubhead: {
        fontSize: 14,
        textAlign: 'center'
    },
    guessDistribution: {
        marginVertical: 10,
        alignItems: 'center',
    },
    button: {
        alignSelf: 'center',
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#2C41FF",
        borderRadius: 20,
        borderColor: "gray",
        height: 35,
        padding: 20
    },
})